import { IInsertQuestionToExam, Question } from '../../types';
import {
  Avatar,
  Checkbox,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BASE_URL, DEFAULT_ERROR } from '../../util/constants';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useAppDispatch } from '../../app/hooks';
import { deleteQuestion } from '../../app/features/questions/actions';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';

interface AddQuestionsTableProps {
  questions: Question[];
  selectQuestion: (questionId: number) => void;
  unSelectQuestion: (questionId: number) => void;
  isSelected: (questionId: number) => boolean;
  value: number;
}

const AddQuestionsTable: React.FC<AddQuestionsTableProps> = ({
  questions,
  selectQuestion,
  unSelectQuestion,
  isSelected,
  value,
}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const t = useTranslate();

  const handleDeleteQuestion = (questionsId: number) => {
    if (window.confirm(t('confirm-del-q'))) {
      dispatch(deleteQuestion(questionsId))
        .then((res) => {
          if (res.type === deleteQuestion.fulfilled.type) {
            notify.success(t('del-success'));
          } else {
            notify.error(t('del-fail'));
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  return (
    <TableContainer overflow='auto'>
      <Table variant='simple' w='100%'>
        <Thead fontSize='1rem'>
          <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
            <Th w='20px'>{t('selected')}</Th>
            <Th w='50px'>{t('num')}</Th>
            <Th>{t('img')}</Th>
            <Th>{t('txt')}</Th>
            <Th>{t('reason')}</Th>
            <Th>{t('actions')}</Th>
          </Tr>
        </Thead>
        <Tbody fontSize='.9rem'>
          {questions.map((q, index) => (
            <Tr key={q.questionId}>
              <Td textAlign='center'>
                <Checkbox
                  borderColor='#D9D9D9'
                  isChecked={isSelected(q.questionId)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      selectQuestion(q.questionId);
                    } else {
                      unSelectQuestion(q.questionId);
                    }
                  }}
                />
              </Td>
              <Td>{value + index + 1}</Td>
              <Td>
                <Image src={q.questionImage} w='7rem' h='3.5rem' />
              </Td>
              <Td>{q.questiontext}</Td>
              <Td>{q.questionReasone}</Td>
              <Td textAlign='center'>
                <Menu placement='bottom'>
                  <MenuButton>
                    <BsThreeDotsVertical cursor='pointer' />
                  </MenuButton>
                  <MenuList
                    minW='36'
                    textAlign='center'
                    fontSize='.9rem'
                    color='#7D7D7D'
                  >
                    <MenuItem
                      fontWeight='bold'
                      onClick={handleDeleteQuestion.bind(null, q.questionId)}
                    >
                      {t('del-q')}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AddQuestionsTable;
