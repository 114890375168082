import { Alert, AlertIcon, ModalContent } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { editQuestion } from '../../../app/features/questions/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Loader from '../../../components/Loader';
import useNotification from '../../../hooks/notification';
import useGetQuestionById from '../../../hooks/questions-hook';
import { useTranslate } from '../../../languages';
import { ICreateQuestion, QuestionDetails } from '../../../types';
import DraggableModal from '../DraggableModalContent';
import EditMCQModal from './EditMCQModal';

interface EditQuestionModalContentProps {
  questionId: string;
  type: string;
  onClose: () => void;
}

const EditQuestionModalContent: React.FC<EditQuestionModalContentProps> = ({
  questionId,
  type,
  onClose,
}) => {
  const t = useTranslate();
  const question = useGetQuestionById(questionId);
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { isEditing } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      isEditing:
        state.questions.loading &&
        lastAction.type === editQuestion.pending.type,
    };
  });

  const handleEditDraggableQuestion = (
    questionData: any,
    image: File | null
  ) => {
    const formData = new FormData();
    if (image) {
      formData.append('Files', image);
    }

    const data: QuestionDetails = {
      Answer: questionData.answers,
      questionCorrectAnswerId: question.data.questionCorrectAnswerId,
      questionId: question.data.questionId,
      questionImage: questionData.questionImage,
      questionIsDraggable: questionData.questionIsDraggable,
      questionReasone: questionData.questionReasone,
      questiontext: questionData.questiontext,
    };

    dispatch(editQuestion({ data, formData })).then((res) => {
      if (res.type === editQuestion.fulfilled.type) {
        notify.success(t('upt-success'));
        onClose();
      } else {
        notify.error(JSON.stringify(res.payload));
      }
    });
  };

  // console.log('question', question);

  if (question.loading || question.error || !question.data.questionId) {
    return (
      <ModalContent>
        {question.loading ? (
          <Loader size='xl' />
        ) : (
          <Alert status='error'>
            <AlertIcon />
            {question.error}
          </Alert>
        )}
      </ModalContent>
    );
  }

  return type === 'mcq' ? (
    <EditMCQModal
      onClose={onClose}
      isEditing={isEditing}
      data={question.data}
      setData={question.setData}
    />
  ) : (
    <DraggableModal
      type='edit'
      btnText={t('edit')}
      handleBtnClick={handleEditDraggableQuestion}
      isLoading={isEditing}
      title={t('create-drag-q')}
      initialState={{
        answers: question.data.Answer,
        questionCorrectAnswerId:
          question.data.questionCorrectAnswerId?.toString(),
        questionImage: question.data.questionImage,
        questionIsDraggable: question.data.questionIsDraggable,
        questionReasone: question.data.questionReasone,
        questiontext: question.data.questiontext,
        questionNumber: '',
        questionLanguageId: null,
        questionDeptId: null,
      }}
    />
  );
};

export default EditQuestionModalContent;
