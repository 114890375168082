import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetPackageExams } from '../../../hooks/packages';
import { ITEMS_PER_PAGE } from '../../../util/constants';
import TableLoader from '../../../components/loader/TableLoader';
import ExamsTable from '../../exams/ExamsTable';
import Pagination from '../../../components/Pagination/Pagination';
import { useTranslate } from '../../../languages';

interface PackageExamsProps {}

const PackageExams: React.FC<PackageExamsProps> = ({}) => {
  const { packageId } = useParams();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const {
    data: { exams, numberOfPages, totalCount },
    error,
    isLoading,
  } = useGetPackageExams(packageId!, page, itemsPerPage);
  const t = useTranslate();

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('pack')} - {packageId} - {t('exams')}
        </Text>
      </Flex>
      <Box bg='#fff'>
        {isLoading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        ) : (
          <ExamsTable exams={exams} />
        )}
        {exams && (
          <>
            <Divider
              borderBottomWidth='2px'
              borderColor='rgba(0, 0, 0, 0.4)'
              mt='2rem'
            />
            <Pagination
              activePage={page}
              numberOfPages={numberOfPages}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              totalCount={totalCount}
              count={exams.length}
              changeItemserPerPage={(value) => setItemsPerPage(value)}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default PackageExams;
