import {
  HStack,
  Input,
  ModalBody,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  Flex,
  Button,
  chakra,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { LANGUAGES, PARTS } from '../../util/constants';
import Loader from '../../components/Loader';
import { ICreateQuestion, Point } from '../../types';
import { useRef, useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { useTranslate } from '../../languages';

interface DraggableModalContentProps {
  title: string;
  btnText: string;
  handleBtnClick: (question: ICreateQuestion, image: File | null) => void;
  initialState: ICreateQuestion;
  isLoading: boolean;
  type: 'add' | 'edit';
}

const DraggableModalContent: React.FC<DraggableModalContentProps> = ({
  title,
  btnText,
  handleBtnClick,
  initialState,
  isLoading,
  type,
}) => {
  const [questionData, setQuestionData] = useState(initialState);
  const [image, setImage] = useState<File | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [points, setPoints] = useState<Point[]>([]);
  const t = useTranslate();

  // darwing points on canvas on each change on points
  useEffect(() => {
    // drawing points on canvas
    const canvas = canvasRef.current,
      ctx = canvas?.getContext('2d');

    if (canvas && ctx) {
      showCircles(points, ctx, canvas.width, canvas.height);
    }
  }, [points]);

  // initialize canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    init();
  }, [canvasRef.current, image]);

  const handleInputChange = (
    e:
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    let value: string | number = e.target.value;
    if (name === 'questionDeptId' || name === 'questionLanguageId') {
      value = +value;
    }
    setQuestionData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const init = (clear?: boolean) => {
    const canvas = canvasRef.current,
      ctx = canvas?.getContext('2d');

    if (!canvas || !ctx) return;

    const img = new Image();
    let imageUrl = image
      ? URL.createObjectURL(image)
      : questionData.questionImage;
    img.src = imageUrl;

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
    if (clear) {
      return setPoints([]);
    }
    try {
      const coordinatesString = questionData.answers?.[0]?.answerText;
      if (coordinatesString) {
        const coordinates = JSON.parse(coordinatesString) as Point[];
        setPoints(coordinates);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const coordinatesString = JSON.stringify(points);

    const newQuestionData: ICreateQuestion = {
      ...questionData,
      questionCorrectAnswerId: coordinatesString,
      answers: [{ ...questionData.answers[0], answerText: coordinatesString }],
    };

    handleBtnClick(newQuestionData, image);
  };

  const handleDoubleClickOnCanvas = (e: MouseEvent<HTMLCanvasElement>) => {
    const x = e.nativeEvent.offsetX,
      y = e.nativeEvent.offsetY;

    addPoint(x, y);
  };

  const addPoint = (x: number, y: number) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const point = {
      x: Math.round((x / canvas.width) * 100),
      y: Math.round((y / canvas.height) * 100),
      id: points.length + 1,
    };

    setPoints((prev) => [...prev, point]);
  };

  return (
    <ModalContent my='1.5rem'>
      <ModalHeader fontSize='1.7rem' fontWeight='bold' textAlign='center'>
        {title}
      </ModalHeader>
      <ModalBody userSelect='none'>
        <form onSubmit={handleSubmit}>
          <Input
            required={type === 'add'}
            type='file'
            onChange={(e) => {
              if (e.target.files) {
                setImage(e.target.files[0]);
              }
            }}
            mb='10px'
          />
          <chakra.canvas
            w='100%'
            h='250px'
            border='1px solid crimson'
            ref={canvasRef}
            mb='10px'
            onDoubleClick={handleDoubleClickOnCanvas}
          ></chakra.canvas>
          <Button
            colorScheme='blue'
            size='md'
            mb='5'
            onClick={init.bind(null, true)}
          >
            {t('reanswer')}
          </Button>
          <FormControl mb='10px'>
            <FormLabel mb={0}>{t('q-txt')}</FormLabel>
            <Textarea
              required
              name='questiontext'
              value={questionData.questiontext}
              onChange={handleInputChange}
              variant='flushed'
              placeholder={t('q-txt')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
            />
          </FormControl>
          <FormControl mb='10px'>
            <FormLabel mb={0}>{t('reason')}</FormLabel>
            <Textarea
              required
              name='questionReasone'
              value={questionData.questionReasone}
              onChange={handleInputChange}
              variant='flushed'
              placeholder={t('reason')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='10px'
            />
          </FormControl>
          {type === 'add' && (
            <>
              <Select
                required
                mb='10px'
                placeholder={t('lang')}
                name='questionLanguageId'
                value={questionData.questionLanguageId?.toString()}
                onChange={handleInputChange}
              >
                {LANGUAGES.map((lang) => (
                  <option key={lang.id} value={lang.id}>
                    {lang.name}
                  </option>
                ))}
              </Select>

              <RadioGroup
                aria-required={true}
                display='flex'
                justifyContent='center'
                my='.8rem'
                onChange={(value) => {
                  setQuestionData((prev) => {
                    return {
                      ...prev,
                      questionDeptId: +value,
                    };
                  });
                }}
              >
                <HStack>
                  {PARTS.map((part) => (
                    <Radio
                      key={part.id}
                      value={part.id.toString()}
                      colorScheme='yellow'
                    >
                      {t('part')[part.id - 1]}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </>
          )}
          <Flex my='20px' justifyContent='center'>
            <Button type='submit' colorScheme='blue'>
              {isLoading ? <Loader size='md' /> : btnText}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default DraggableModalContent;

const showCircles = (
  points: { x: number; y: number; id: number }[],
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number
) => {
  for (const item of points) {
    const { id } = item;
    ctx.beginPath();
    ctx.fillStyle = '#407BFF';
    const x = Math.ceil((item.x / 100) * width),
      y = Math.ceil((item.y / 100) * height);
    ctx.arc(x, y, 20, 0, 2 * Math.PI);
    ctx.fill();
    // ctx.stroke();
    ctx.fillStyle = '#fff';
    ctx.font = '22px Inter';
    ctx.fillText(id.toString(), x - 5, y + 5);
  }
};
