import {
  Button,
  Flex,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import {
  insertPackage,
  updatePackage,
} from '../../app/features/packages/actions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Loader from '../../components/Loader';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';
import { ICreatePackage, Package } from '../../types';
import { DEFAULT_ERROR } from '../../util/constants';

interface CreateUserModalProps {
  onClose: () => void;
  initialState?: ICreatePackage;
  type: 'create' | 'edit';
  btnText: string;
  title: string;
  isLoading?: boolean;
}

const PackageModalContent: React.FC<CreateUserModalProps> = ({
  onClose,
  initialState,
  type,
  btnText,
  title,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { loading } = useAppSelector((state) => {
    return {
      loading: state.packages.loading,
    };
  });
  const [data, setData] = useState({
    packageArabicText: '',
    packageEnglishText: '',
    packageDutchText: '',
    packageExamLanguage: '',
    packageBestSeller: false,
    packagePrice: 0,
    packageOfferPrice: 0,
    packageDuration: 0,
  });
  const t = useTranslate();

  useEffect(() => {
    if (initialState) {
      setData(initialState);
    }
  }, [initialState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      const name = e.target.name;
      let value: string | number = e.target.value;
      if (e.target.type === 'number') {
        value = +value;
      }
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (type === 'create') {
      dispatch(insertPackage(data as ICreatePackage))
        .then((res) => {
          if (res.type === insertPackage.fulfilled.type) {
            notify.success(t('ins-success'));
            onClose();
          } else {
            notify.error(res.payload as string);
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    } else {
      dispatch(updatePackage(data as Package))
        .then((res) => {
          if (res.type === updatePackage.fulfilled.type) {
            notify.success(t('upt-success'));
            onClose();
          } else {
            notify.error(res.payload as string);
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  return (
    <ModalContent my='2rem'>
      <ModalHeader fontSize='2rem' textAlign='center'>
        {title}
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <Loader size='xl' />
        ) : (
          <form onSubmit={handleSubmit}>
            <Input
              required
              name='packageArabicText'
              value={data.packageArabicText}
              onChange={handleInputChange}
              type='text'
              variant='flushed'
              placeholder={t('ar-name')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            <Input
              required
              name='packageEnglishText'
              value={data.packageEnglishText}
              onChange={handleInputChange}
              type='text'
              variant='flushed'
              placeholder={t('en-name')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            <Input
              required
              name='packageDutchText'
              value={data.packageDutchText}
              onChange={handleInputChange}
              type='text'
              variant='flushed'
              placeholder={t('du-name')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            <Input
              required
              name='packageExamLanguage'
              value={data.packageExamLanguage}
              onChange={handleInputChange}
              type='text'
              variant='flushed'
              placeholder={t('exam-langs')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            <InputGroup alignItems='center' columnGap='20px'>
              <Text>{t('bestseller')}</Text>
              <Switch
                isChecked={data.packageBestSeller}
                onChange={(e) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      packageBestSeller: !prev.packageBestSeller,
                    };
                  });
                }}
              />
            </InputGroup>
            <Input
              required
              name='packagePrice'
              value={data.packagePrice ? data.packagePrice : ''}
              onChange={handleInputChange}
              type='number'
              variant='flushed'
              placeholder={t('price')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            {/* <Input
                // required
                name='packageOfferPrice'
                value={data.packageOfferPrice ? data.packageOfferPrice : ''}
                onChange={handleInputChange}
                type='number'
                variant='flushed'
                placeholder={t('offer-price')}
                fontFamily='Inter'
                fontWeight='500'
                fontSize='.9rem'
                color='rgba(0, 0, 0, 1)'
                _placeholder={{
                  color: 'rgba(0, 0, 0, 1)',
                  fontSize: '.9rem',
                }}
                mb='1rem'
              /> */}
            <Input
              required
              name='packageDuration'
              value={data.packageDuration ? data.packageDuration : ''}
              onChange={handleInputChange}
              type='number'
              variant='flushed'
              placeholder={t('duration')}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='1rem'
            />
            <Flex mb='20px' justifyContent='center'>
              <Button type='submit' colorScheme='blue'>
                {loading ? <Loader size='md' /> : btnText}
              </Button>
            </Flex>
          </form>
        )}
      </ModalBody>
    </ModalContent>
  );
};

export default PackageModalContent;
