import {
  Input,
  ModalBody,
  ModalContent,
  ModalHeader,
  Flex,
  Button,
  Image,
  VStack,
} from '@chakra-ui/react';
import { useTranslate } from '../../../languages';
import useNotification from '../../../hooks/notification';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  addAnswerToQuestion,
  deleteQuestionAnswer,
  editQuestion,
} from '../../../app/features/questions/actions';
import { QuestionDetails } from '../../../types';
import FlushedTextArea from '../../../components/inputs';
import QuestionAnswers from '../../../components/QuestionAnswers';

interface EditMCQModalProps {
  onClose: () => void;
  data: QuestionDetails;
  setData: React.Dispatch<React.SetStateAction<QuestionDetails>>;
  isEditing: boolean;
}

const EditMCQModal: React.FC<EditMCQModalProps> = ({
  onClose,
  data,
  setData,
  isEditing,
}) => {
  const t = useTranslate();
  const [image, setImage] = useState<File | null>(null);
  const notify = useNotification();
  const dispatch = useAppDispatch();

  const { isAddingNewAnswer } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      isAddingNewAnswer:
        state.questions.loading &&
        lastAction.type === addAnswerToQuestion.pending.type,
    };
  });

  // console.log('question', data);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleChangeAnswer = (answerId: number, value: string) => {
    setData((prev) => {
      const answers = [...prev.Answer];
      const index = answers.findIndex((a) => a.answerId === answerId);
      if (index != -1) {
        answers[index].answerText = value;
      }
      return {
        ...prev,
        Answer: answers,
      };
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    if (image) {
      formData.append('Files', image);
    }
    dispatch(editQuestion({ data, formData })).then((res) => {
      if (res.type === editQuestion.fulfilled.type) {
        notify.success(t('upt-success'));
        onClose();
      } else {
        notify.error(JSON.stringify(res.payload));
      }
    });
  };

  const handleAddAnswer = () => {
    dispatch(
      addAnswerToQuestion({
        questionId: data.questionId,
        answer: '',
      })
    ).then((res) => {
      if (res.type === addAnswerToQuestion.fulfilled.type) {
        setData((prev) => ({
          ...prev,
          Answer: [
            ...prev.Answer,
            {
              answerId: (res.payload as any)?.answerId,
              answerText: '',
              questionReasone: '',
            },
          ],
        }));
      }
    });
  };

  const handleDeleteAnswer = (answerId: number | string) => {
    dispatch(
      deleteQuestionAnswer({
        answerId,
      })
    ).then((res) => {
      if (res.type === deleteQuestionAnswer.fulfilled.type) {
        setData((prev) => ({
          ...prev,
          Answer: prev.Answer.filter((a) => a.answerId !== answerId),
        }));
      }
    });
  };

  let imageUrl = null;
  if (image) {
    imageUrl = URL.createObjectURL(image);
  } else {
    imageUrl = data.questionImage;
  }

  return (
    <ModalContent my='1.5rem'>
      <ModalHeader fontSize='1.8rem' textAlign='center'>
        {t('edit-q')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Input
            type='file'
            onChange={(e) => {
              if (e.target.files) {
                const file = e.target.files[0];
                setImage(file);
              }
            }}
          />
          <Image src={imageUrl} maxH='300px' mb={2} />
          <VStack spacing={'1rem'}>
            <FlushedTextArea
              label={t('q-txt')}
              name='questiontext'
              value={data.questiontext}
              onChange={handleInputChange}
            />
            <FlushedTextArea
              label={t('reason')}
              name='questionReasone'
              value={data.questionReasone}
              onChange={handleInputChange}
            />
          </VStack>
          <QuestionAnswers
            answers={data.Answer}
            questionCorrectAnswerId={data.questionCorrectAnswerId}
            questionCorrectAnswerId2={data.questionCorrectAnswerId2}
            questionCorrectAnswerId3={data.questionCorrectAnswerId3}
            onAddAnswer={handleAddAnswer}
            onAnswerChange={handleChangeAnswer}
            onDeleteAnswer={handleDeleteAnswer}
            addNewAnswerLoading={isAddingNewAnswer}
            setQuestionData={setData}
          />
          <Flex mb='20px' justifyContent='center'>
            <Button type='submit' colorScheme='blue' isLoading={isEditing}>
              {t('save')}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default EditMCQModal;
