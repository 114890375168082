import { useState } from 'react';

interface useMutaitonProps<TParams, TData> {
  mutationFn: (params: TParams) => Promise<TData>;
  onSuccess?: (data: TData, params: TParams) => void;
  onError?: (error: any) => void;
}

export const useMutation = <TParams, TData>({
  mutationFn,
  onError,
  onSuccess,
}: useMutaitonProps<TParams, TData>) => {
  const [data, setData] = useState<TData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const mutate = async (params: TParams) => {
    try {
      setIsLoading(true);
      const res = await mutationFn(params);
      setData(res);
      onSuccess?.(res, params);
    } catch (error: any) {
      setError(error?.response?.data ?? 'error');
      onError?.(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    mutate,
  };
};
