import {
  Flex,
  Text,
  Box,
  Button,
  HStack,
  RadioGroup,
  Radio,
  Progress,
  InputGroup,
  Input,
  InputLeftElement,
  Divider,
  Spacer,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs';
import { ITEMS_PER_PAGE, PARTS } from '../../util/constants';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getQuestions,
  getQuestionsRelatedToExam,
  insertQuestionToExam,
  searchQuestions,
} from '../../app/features/questions/actions';
import TableLoader from '../../components/loader/TableLoader';
import QuestionsTable from '../questions/QuestionsTable';
import Pagination from '../../components/Pagination/Pagination';
import { IInsertQuestionToExam } from '../../types';
import useNotification from '../../hooks/notification';
import Loader from '../../components/Loader';
import AddQuestionsTable from './AddQuestionTable';
import { useTranslate } from '../../languages';

interface AddQuestionsToExamProps {}

const AddQuestionsToExam: React.FC<AddQuestionsToExamProps> = ({}) => {
  const { examId } = useParams<{ examId: string }>();
  const t = useTranslate();
  const [partId, setPartId] = useState<1 | 2 | 3>(1);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const navigate = useNavigate();
  const [selectedQuestions, setSelectedQuestions] = useState<{
    1: IInsertQuestionToExam[];
    2: IInsertQuestionToExam[];
    3: IInsertQuestionToExam[];
  }>({ 1: [], 2: [], 3: [] });
  const {
    loading,
    error,
    questions,
    numberOfPages,
    totalCount,
    loadingFinishExam,
  } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      loading: state.questions.loading,
      error: state.questions.error,
      questions: state.questions.data,
      numberOfPages: state.questions.numberOfPages,
      totalCount: state.questions.totalCount,
      loadingFinishExam: lastAction.type === insertQuestionToExam.pending.type,
    };
  });

  useEffect(() => {
    setPage(1);
  }, [itemsPerPage, partId]);

  useEffect(() => {
    dispatch(
      getQuestionsRelatedToExam({
        examId: examId!,
        exist: false,
        pageNumber: page,
        partId,
        rowCount: itemsPerPage,
      })
      // searchQuestions({
      //   pageNumber: 1,
      //   rowCount: 10,
      //   text: 'test-multi',
      // })
    );
  }, [partId, page, itemsPerPage]);

  const handleAddQuestionsToExam = () => {
    const data = selectedQuestions[1]
      .concat(selectedQuestions[2])
      .concat(selectedQuestions[3]);

    dispatch(insertQuestionToExam(data)).then((res) => {
      if (res.type === insertQuestionToExam.fulfilled.type) {
        notify.success(t('ins-success'));
        navigate(`/exam/${examId}/content`);
      } else {
        notify.error(res.payload as string);
      }
    });
  };

  const selectQuestion = (questionId: number) => {
    if (
      selectedQuestions[partId].length >= PARTS[partId - 1].numberOfQuestions
    ) {
      return notify.info(t('complete-part'));
    }
    setSelectedQuestions((prev) => {
      const questions = [...selectedQuestions[partId]];
      questions.push({ examId: +examId!, questionId });
      return {
        ...prev,
        [partId]: questions,
      };
    });
  };

  const unSelectQuestoin = (questionId: number) => {
    setSelectedQuestions((prev) => {
      const questions = selectedQuestions[partId].filter(
        (item) => item.questionId !== questionId
      );
      return {
        ...prev,
        [partId]: questions,
      };
    });
  };

  const isQuestionSelected = (questionId: number) => {
    return !!selectedQuestions[partId].find(
      (item) => item.questionId === questionId
    );
  };

  const prgressValue = Math.round(
    (selectedQuestions[partId].length / PARTS[partId - 1].numberOfQuestions) *
      100
  );

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('sel-q')}
        </Text>
        <Box display='flex' columnGap='20px'>
          <Button
            colorScheme='blue'
            isLoading={loadingFinishExam}
            onClick={handleAddQuestionsToExam}
          >
            {t('finish-exam')}
          </Button>
        </Box>
      </Flex>
      <Flex
        bg='#fff'
        mb='40px'
        alignItems='center'
        justifyContent='space-between'
        pe='1rem'
      >
        <HStack>
          {PARTS.map((part) => (
            <Box
              key={part.id}
              py='20px'
              px='15px'
              cursor='pointer'
              color='blue.main'
              fontWeight={600}
              fontSize='1rem'
              className={part.id === partId ? 'active' : ''}
              sx={{ '&.active': { borderBottom: '4px solid #09003F' } }}
              onClick={() => {
                setPartId(part.id as 1);
              }}
            >
              {part.name}
            </Box>
          ))}
        </HStack>
        <Box minW='300px' w='33.333%'>
          <HStack>
            <Text color='blue.main' fontSize='.7rem' fontWeight={500}>
              {t('part')[partId - 1]} {t('selected-qs')}
            </Text>
            <Spacer />
            <Text>{selectedQuestions[partId].length}</Text>
          </HStack>
          <Progress
            borderRadius={5}
            colorScheme='blue'
            max={100}
            value={prgressValue}
          />
        </Box>
      </Flex>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : error ? (
          <Alert>
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <AddQuestionsTable
            questions={questions}
            selectQuestion={selectQuestion}
            unSelectQuestion={unSelectQuestoin}
            isSelected={isQuestionSelected}
            value={(page - 1) * itemsPerPage}
          />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={page}
          numberOfPages={100}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          count={questions.length}
          changeItemserPerPage={(value) => setItemsPerPage(value)}
        />
      </Box>
    </>
  );
};

export default AddQuestionsToExam;
