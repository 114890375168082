import { extendTheme } from '@chakra-ui/react';

const colors = {
  blue: {
    main: '#09003F',
    '500': '#407BFF',
    '600': '#1e5be3',
  },
  darkBlue: {
    '500': 'rgba(9, 0, 63, 1)',
    '600': 'rgba(9, 0, 63, .8)',
  },
  gray: {
    main: '#E5E5E5',
  },
  yellow: {
    main: '#F8AA05',
  },
};

const components = {
  FormLabel: {
    baseStyle: {
      color: '#B3B3B7',
      mb: 0,
      fontWeight: 500,
    },
  },
};

const theme = {
  colors,
  components,
};

export default theme;
