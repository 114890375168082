import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link, useSearchParams } from 'react-router-dom';
import { deletePackageById } from '../../app/features/packages/actions';
import { useAppDispatch } from '../../app/hooks';
import useNotification from '../../hooks/notification';
import { ICreatePackage, Package, User } from '../../types';
import { BASE_URL, DEFAULT_ERROR } from '../../util/constants';
import { BiChevronRight } from 'react-icons/bi';
import EditPackage from './EditPackage';
import { useTranslate } from '../../languages';

interface UsersTableProps {
  packages: Package[];
}

const PackagesTable: React.FC<UsersTableProps> = ({ packages }) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const [search, setSearch] = useSearchParams();
  const t = useTranslate();

  const handleDeletePackage = (packageId: number) => {
    if (window.confirm(t('confirm-del-pack'))) {
      dispatch(deletePackageById(packageId))
        .then((res) => {
          if (res.type === deletePackageById.fulfilled.type) {
            notify.success(t('del-success'));
          } else {
            notify.error(t('del-fail'));
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  const packageId = search.get('packageId'),
    mode = search.get('mode');

  return (
    <>
      <Modal
        isOpen={!!(mode === 'edit' && packageId)}
        onClose={() => setSearch({})}
      >
        <ModalOverlay />
        <EditPackage packageId={packageId!} onClose={() => setSearch({})} />
      </Modal>
      <TableContainer overflow='auto'>
        <Table variant='simple' cellPadding='0' cellSpacing='0'>
          <Thead fontSize='1rem'>
            <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
              <Th>{t('name')}</Th>
              <Th>{t('price')}</Th>
              {/* <Th>{t('offer-price')}</Th> */}
              <Th>{t('bestseller')}</Th>
              <Th>{t('num-of-exams')}</Th>
              <Th>{t('langs')}</Th>
              <Th>{t('pack-view')}</Th>
              <Th>{t('actions')}</Th>
            </Tr>
          </Thead>
          <Tbody fontSize='.8rem'>
            {packages.map((p, index) => (
              <Tr key={p.packageId}>
                <Td>{p.packageText ?? p.packageEnglishText}</Td>
                <Td>{p.packagePrice}</Td>
                {/* <Td>{p.packageOfferPrice}</Td> */}
                <Td>
                  <Switch isChecked={p.packageBestSeller} />
                </Td>
                <Td>{p.examCount ?? 0}</Td>
                <Td>{p.packageExamLanguage}</Td>
                <Td>
                  <Link to={`/package/${p.packageId}/view`}>
                    <Button size='sm' colorScheme='blue' borderRadius={0}>
                      {t('pack-view')}
                      <BiChevronRight
                        fontSize='1.5rem'
                        className='rotate-180'
                      />
                    </Button>
                  </Link>
                </Td>
                <Td textAlign='center'>
                  <Menu placement='bottom'>
                    <MenuButton>
                      <BsThreeDotsVertical cursor='pointer' />
                    </MenuButton>
                    <MenuList
                      minW='36'
                      textAlign='center'
                      fontSize='.9rem'
                      color='#7D7D7D'
                    >
                      <MenuItem fontWeight='bold'>
                        <Link to={`/package/${p.packageId}/add-exams`}>
                          {t('add-exams')}
                        </Link>
                      </MenuItem>
                      <MenuItem
                        fontWeight='bold'
                        onClick={() =>
                          setSearch({
                            mode: 'edit',
                            packageId: p.packageId.toString(),
                          })
                        }
                      >
                        {t('edit-pack')}
                      </MenuItem>
                      <MenuItem
                        fontWeight='bold'
                        onClick={handleDeletePackage.bind(null, p.packageId)}
                      >
                        {t('del-pack')}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PackagesTable;
