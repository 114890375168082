import { useState, useEffect } from 'react';
import { Exam, Package } from '../types';
import api from '../app/api';
import { DEFAULT_ERROR } from '../util/constants';

interface PackageName {
  packageId: number;
  packageEnglishText: string;
}

export const useGetPackageById = (packageId: string | null) => {
  const [data, setData] = useState<Package | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!packageId) return;
    (async () => {
      try {
        setIsLoading(true);
        const res = await api.get<Package>(`/Admin/Select/Package/By/${packageId}`);
        setData(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [packageId]);
  return { data, isLoading };
};

export const useGetPackages = () => {
  const [data, setData] = useState<PackageName[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await api.get<PackageName[]>(`/Admin/Select/All/Package/Name`);
        setData(res.data);
        setError('');
      } catch (err: any) {
        setError(err.response?.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return { data, isLoading, error };
};

export const useGetPackageExams = (
  packageId: number | string,
  pageNumber: number,
  rowCount: number
) => {
  const [data, setData] = useState<{
    numberOfPages: number;
    totalCount: number;
    exams: Exam[];
  }>({ numberOfPages: 0, totalCount: 0, exams: [] });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await api.get<{
          item1: {
            rawCount: number;
            pageCount: number;
          };
          item2: Exam[];
        }>(`/Admin/Select/Exams/In/Package/${pageNumber}/${rowCount}/${packageId}`);
        setData({
          numberOfPages: res.data.item1.pageCount,
          totalCount: res.data.item1.rawCount,
          exams: res.data.item2,
        });
        setError('');
      } catch (err: any) {
        setError(err.response?.data || DEFAULT_ERROR);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [pageNumber, rowCount]);
  return { data, isLoading, error };
};
