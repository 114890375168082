import {
  Skeleton,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SkeletonText,
} from '@chakra-ui/react';
import { ITEMS_PER_PAGE } from '../../util/constants';

interface TableLoaderProps {
  numberOfRows?: number;
}

const TableLoader: React.FC<TableLoaderProps> = ({ numberOfRows }) => {
  return (
    <TableContainer overflow='auto'>
      <Table variant='simple' fontSize='1.1rem'>
        <Thead>
          <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
            <Th py='20px' fontSize='1.1rem'>
              <Skeleton startColor='gray' endColor='gray.200' h='20px' />
            </Th>
            <Th py='20px' fontSize='1.1rem'>
              <Skeleton startColor='gray' endColor='gray.200' h='20px' />
            </Th>
            <Th py='20px' fontSize='1.1rem' w='120px'>
              <Skeleton startColor='gray' endColor='gray.200' h='20px' />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {Array.from(new Array(numberOfRows ?? ITEMS_PER_PAGE)).map((val, index) => (
            <Tr key={index}>
              <Td>
                <Skeleton startColor='gray' endColor='gray.200' h='20px' />
              </Td>
              <Td>
                <Skeleton startColor='gray' endColor='gray.200' h='20px' />
              </Td>
              <Td>
                <Skeleton startColor='gray' endColor='gray.200' h='20px' />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableLoader;
