import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { useGetPackages } from '../../hooks/packages';
import { useState } from 'react';
import { ICreateUserData } from '../../types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { createUser } from '../../app/features/users/actions';
import useNotification from '../../hooks/notification';
import { DEFAULT_ERROR } from '../../util/constants';
import Loader from '../../components/Loader';
import { useTranslate } from '../../languages';

interface CreateUserModalContentProps {
  onClose: () => void;
}

const CreateUserModalContent: React.FC<CreateUserModalContentProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();

  const { loading } = useAppSelector((state) => {
    return {
      loading: state.users.loading,
    };
  });

  const {
    data: packages,
    isLoading: loadingPackages,
    error: packagesError,
  } = useGetPackages();

  const [user, setUser] = useState<ICreateUserData>({
    userCode: null,
    userEmail: '',
    userName: '',
    userPassword: '',
  });
  const [packageId, setPackageId] = useState(0);
  const t = useTranslate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleCreateUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createUser({ userData: user, packageId }))
      .then((res) => {
        if (res.type === createUser.fulfilled.type) {
          notify.success(t('ins-success'));
          onClose();
        } else {
          notify.error(res.payload as string);
        }
      })
      .catch((err) => {
        notify.error(DEFAULT_ERROR);
      });
  };

  return (
    <ModalContent>
      <ModalHeader fontSize='2rem' textAlign='center'>
        {t('create-user')}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleCreateUser}>
          <Input
            isRequired
            name='userEmail'
            onChange={handleInputChange}
            type='email'
            variant='flushed'
            placeholder={t('email')}
            fontFamily='Inter'
            fontWeight='500'
            fontSize='1.18rem'
            color='rgba(0, 0, 0, 1)'
            _placeholder={{
              color: 'rgba(0, 0, 0, 1)',
              fontSize: ['13px', '16px', '20px'],
            }}
            mb='30px'
          />
          <Input
            isRequired
            name='userName'
            onChange={handleInputChange}
            type='text'
            variant='flushed'
            placeholder={t('username')}
            fontFamily='Inter'
            fontWeight='500'
            fontSize='1.18rem'
            color='rgba(0, 0, 0, 1)'
            _placeholder={{
              color: 'rgba(0, 0, 0, 1)',
              fontSize: ['13px', '16px', '20px'],
            }}
            mb='30px'
          />
          <Input
            isRequired
            name='userPassword'
            onChange={handleInputChange}
            type='password'
            variant='flushed'
            placeholder={t('pass')}
            fontFamily='Inter'
            fontWeight='500'
            fontSize='1.18rem'
            color='rgba(0, 0, 0, 1)'
            _placeholder={{
              color: 'rgba(0, 0, 0, 1)',
              fontSize: ['13px', '16px', '20px'],
            }}
            mb='30px'
          />
          <Input
            name='userCode'
            onChange={handleInputChange}
            type='text'
            variant='flushed'
            placeholder={t('code')}
            fontFamily='Inter'
            fontWeight='500'
            fontSize='1.18rem'
            color='rgba(0, 0, 0, 1)'
            _placeholder={{
              color: 'rgba(0, 0, 0, 1)',
              fontSize: ['13px', '16px', '20px'],
            }}
            mb='30px'
          />
          <Select
            mb='30px'
            placeholder={t('select-pack')}
            value={packageId}
            onChange={(e) => setPackageId(+e.target.value)}
          >
            {loadingPackages ? (
              <Loader size='md' />
            ) : (
              packages.map((p) => (
                <option key={p.packageId} value={p.packageId}>
                  {p.packageEnglishText}
                </option>
              ))
            )}
          </Select>
          <Flex mb='20px' justifyContent='center'>
            <Button type='submit' colorScheme='blue'>
              {loading ? <Loader size='md' /> : t('create-user')}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default CreateUserModalContent;
