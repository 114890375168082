import { Box, HStack } from '@chakra-ui/react';
import { PARTS } from '../util/constants';

interface ExamPartsProps {
  setPartId: (partId: number) => void;
  partId: number;
}

const ExamParts: React.FC<ExamPartsProps> = ({ partId, setPartId }) => {
  return (
    <HStack bg='#fff' mb='40px' spacing='15px'>
      {PARTS.map((part) => (
        <Box
          key={part.id}
          py='20px'
          px='15px'
          cursor='pointer'
          color='blue.main'
          fontWeight={600}
          fontSize='1rem'
          className={part.id === partId ? 'active' : ''}
          sx={{ '&.active': { borderBottom: '4px solid #09003F' } }}
          onClick={() => {
            setPartId(part.id);
          }}
        >
          {part.name}
        </Box>
      ))}
    </HStack>
  );
}

export default ExamParts