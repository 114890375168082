import { useState, useEffect } from 'react';
import api from '../app/api';

interface Dashboard {
  userCount: number;
  registeredUserCount: number;
  nonRegisteredUserCount: number;
  quetionCount: number;
  correctQuetionCount: number;
  nonCorrectQuetionCount: number;
  emptyQuetionCount: number;
  examCount: number;
  packageCount: number;
}

export const useGetDashboard = () => {
  const [data, setData] = useState<Dashboard>({} as Dashboard);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await api.get<Dashboard>(`/Admin/Admin/Dashpord`);
        setData({
          ...res.data,
          registeredUserCount: Math.round(
            (res.data.registeredUserCount / res.data.userCount) * 100
          ),
          nonRegisteredUserCount: Math.round(
            (res.data.nonRegisteredUserCount / res.data.userCount) * 100
          ),
          correctQuetionCount: Math.round(
            (res.data.correctQuetionCount / res.data.quetionCount) * 100
          ),
          nonCorrectQuetionCount: Math.round(
            (res.data.nonCorrectQuetionCount / res.data.quetionCount) * 100
          ),
          emptyQuetionCount: Math.round(
            (res.data.emptyQuetionCount / res.data.quetionCount) * 100
          ),
        });
        setError('');
      } catch (err: any) {
        setError(err.response?.data ?? err.message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return { data, error, isLoading };
};
