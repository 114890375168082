import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
} from '@chakra-ui/react';
import Loader from '../../components/Loader';
import { useTranslate } from '../../languages';
import { IUserDetails } from '../../types';
import { useState, useEffect } from 'react';
import api from '../../app/api';
import FlushedInput from '../../components/FlushedInput';
import FlushedPassword from '../../components/FlushedPassword';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { editUser } from '../../app/features/users/actions';
import useNotification from '../../hooks/notification';

interface EditUserModalContentProps {
  userId: string;
  onClose: () => void;
}

const EditUserModalContent: React.FC<EditUserModalContentProps> = ({
  userId,
  onClose,
}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { isEditing } = useAppSelector((state) => {
    return {
      isEditing: state.users.loading,
    };
  });
  const [user, setUser] = useState<IUserDetails>({
    userCode: '',
    userEmail: '',
    userId: 0,
    userName: '',
    userPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await api.get<IUserDetails>(
          `/Admin/Select/User/By/${userId}`
        );
        setUser(res.data);
        setError('');
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(editUser(user)).then((res) => {
      if (res.type === editUser.fulfilled.type) {
        notify.success(t('edit-sucess'));
        onClose();
      } else {
        notify.error(t('edit-fail'));
      }
    });
  };

  return (
    <ModalContent>
      <ModalHeader fontSize='2rem' textAlign='center'>
        {t('edit-user')}
      </ModalHeader>
      <ModalBody>
        {loading || !user ? (
          <Loader size='xl' />
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <form onSubmit={handleEditUser}>
            <FlushedInput
              value={user.userName}
              name='userName'
              onChange={handleInputChange}
              type='text'
              placeholder={t('username')}
              isRequired
            />
            <FlushedInput
              value={user.userEmail}
              name='userEmail'
              onChange={handleInputChange}
              type='text'
              placeholder={t('email')}
            />
            <FlushedPassword
              value={user.userPassword}
              name='userPassword'
              onChange={handleInputChange}
              placeholder={t('pass')}
            />
            <FlushedInput
              value={user.userCode ?? ''}
              name='userCode'
              onChange={handleInputChange}
              type='text'
              placeholder={t('code')}
            />
            <Flex mb='20px' justifyContent='center'>
              <Button type='submit' colorScheme='blue' disabled={loading}>
                {isEditing ? <Loader size='md' /> : t('save')}
              </Button>
            </Flex>
          </form>
        )}
      </ModalBody>
    </ModalContent>
  );
};

export default EditUserModalContent;
