import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message } from '../../../types';
import { DEFAULT_ERROR, ITEMS_PER_PAGE } from '../../../util/constants';
import { getMessages } from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  numberOfPages: number;
  totalCount: number;
  currentPage: number;
  itemsPerPage: number;
  data: Message[];
}

const initialState: InitialState = {
  loading: false,
  error: '',
  numberOfPages: 0,
  totalCount: 0,
  currentPage: 1,
  itemsPerPage: ITEMS_PER_PAGE,
  data: [],
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    changePage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    changeItemsPerPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.data;
      state.numberOfPages = action.payload.numberOfPages;
      state.currentPage = action.payload.currentPage;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default messagesSlice.reducer;
export const { changePage, changeItemsPerPage } = messagesSlice.actions;
