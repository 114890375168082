import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import themeOjb from './theme';
import { useAppSelector } from '../app/hooks';

interface ChakraCustomProviderProps {
  children: React.ReactNode;
}

const ChakraCustomProvider: React.FC<ChakraCustomProviderProps> = ({
  children,
}) => {
  const { lang } = useAppSelector((state) => {
    return {
      lang: state.content.websiteLang,
    };
  });
  const theme = extendTheme({
    ...themeOjb,
    direction: lang === 'ar' ? 'rtl' : 'ltr',
  });

  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default ChakraCustomProvider;
