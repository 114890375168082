import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../../types';
import { DEFAULT_ERROR } from '../../../util/constants';
import {
  getUsers,
  searchForUsers,
  deleteUser,
  createUser,
  editUser,
} from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  numberOfPages: number;
  totalCount: number;
  data: User[];
  currentUser: null;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  numberOfPages: 0,
  totalCount: 0,
  data: [],
  currentUser: null,
};

const useSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all users
    builder.addCase(getUsers.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
      state.data = action.payload.users;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // search users
    builder.addCase(searchForUsers.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(searchForUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
      state.data = action.payload.users;
    });
    builder.addCase(searchForUsers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // delete user by id
    builder.addCase(deleteUser.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = state.data.filter(
        (user) => user.userId !== action.payload.userId
      );
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // create user
    builder.addCase(createUser.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data.push(action.payload.user);
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // edit user
    builder.addCase(editUser.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      const index = state.data.findIndex(
        (u) => u.userId === action.payload.userId
      );
      if (index != -1) {
        state.data[index].userEmail = action.payload.userEmail;
        state.data[index].userName = action.payload.userName;
      }
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default useSlice.reducer;
export const {} = useSlice.actions;
