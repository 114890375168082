import { createSlice } from '@reduxjs/toolkit';
import { Exam } from '../../../types';
import { DEFAULT_ERROR, FREE_EXAM } from '../../../util/constants';
import {
  addExamsToPackage,
  createExam,
  deleteExam,
  getExamById,
  getExams,
  getExamsNotExistInPackage,
  searchExams,
  setFreeExam,
  updateExam,
} from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  numberOfPages: number;
  totalCount: number;
  currentExam: Exam | null;
  data: Exam[];
}

const initialState: InitialState = {
  loading: false,
  error: '',
  numberOfPages: 0,
  totalCount: 0,
  currentExam: null,
  data: [],
};

const examsSlice = createSlice({
  name: 'exams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch exams
    builder.addCase(getExams.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getExams.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.exams;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getExams.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // search exams
    builder.addCase(searchExams.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(searchExams.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.exams;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(searchExams.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // update exam
    builder.addCase(updateExam.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      const index = state.data.findIndex(
        (e) => e.examId === action.payload.examId
      );
      if (index != -1) {
        state.data[index] = action.payload;
      }
    });
    builder.addCase(updateExam.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // create exam
    builder.addCase(createExam.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data.push(action.payload);
    });
    builder.addCase(createExam.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // delete exam
    builder.addCase(deleteExam.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deleteExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = state.data.filter((e) => e.examId !== action.payload.examId);
    });
    builder.addCase(deleteExam.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // get exams not in package
    builder.addCase(getExamsNotExistInPackage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getExamsNotExistInPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.exams;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getExamsNotExistInPackage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // add exams to package
    builder.addCase(addExamsToPackage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(addExamsToPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(addExamsToPackage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // set exam as free
    builder.addCase(setFreeExam.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(setFreeExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      const examIndex = state.data.findIndex(
        (e) => e.examId === action.payload.examId
      );
      if (examIndex != -1) {
        state.data[examIndex].examName = FREE_EXAM;
      }
    });
    builder.addCase(setFreeExam.rejected, (state, action) => {
      state.loading = false;
      // in case of error notify user
    });

    builder.addCase(getExamById.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getExamById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.currentExam = action.payload;
    });
    builder.addCase(getExamById.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default examsSlice.reducer;
export const {} = examsSlice.actions;
