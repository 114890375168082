import './overview.scss';
import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Progress,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useGetDashboard } from '../../hooks/overview';
import { IoIosPeople } from 'react-icons/io';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import { CgNotes } from 'react-icons/cg';
import { FiBox } from 'react-icons/fi';
import cardIcon from '../../assests/icons/card.svg';
import gitftIcon from '../../assests/icons/gift.svg';
import squareIcon from '../../assests/icons/square.svg';
import Loader from '../../components/Loader';
import { useTranslate } from '../../languages';

interface OverviewProps {}

const Overview: React.FC<OverviewProps> = ({}) => {
  const { error, data, isLoading } = useGetDashboard();
  const t = useTranslate();

  if (isLoading) {
    return <Loader size='xl' />
  }

  if (error) {
    return (
      <Alert status='error'>
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <>
      <Grid
        gridTemplateColumns={['1fr', null, '2fr 1fr 1fr']}
        gap='1.5rem'
        mt='2rem'
      >
        <HStack bg='#fff' py='.4rem'>
          <Box
            flexGrow='1'
            display='flex'
            flexDir='column'
            justifyContent='center'
            alignItems='center'
          >
            <IoIosPeople color='#407BFF' fontSize='3rem' />
            <Text fontWeight={700} fontSize='2rem'>
              {data.userCount}
            </Text>
            <Text color='rgba(80, 80, 80, 0.88)' fontSize='.5rem'>
              {t('total-num-users')}
            </Text>
          </Box>
          <Divider borderColor='rgba(0, 0, 0, 0.5)' orientation='vertical' />
          <Box w='60%' p='1rem'>
            <Box
              color='blue.main'
              fontSize='.7rem'
              fontWeight={500}
              mb='1.5rem'
            >
              <Flex
                alignItems='center'
                justifyContent='space-between'
                mb='.2rem'
              >
                <Text>{t('registered-users')}</Text>
                <Text>{data.registeredUserCount}%</Text>
              </Flex>
              <Progress
                borderRadius='5px'
                h='.4rem'
                max={100}
                value={data.registeredUserCount}
              />
            </Box>
            <Box color='blue.main' fontSize='.7rem' fontWeight={500}>
              <Flex
                alignItems='center'
                justifyContent='space-between'
                mb='.2rem'
              >
                <Text>{t('not-registered-users')}</Text>
                <Text>{data.nonRegisteredUserCount}%</Text>
              </Flex>
              <Progress
                borderRadius='5px'
                h='.4rem'
                max={100}
                value={data.nonRegisteredUserCount}
              />
            </Box>
            <Link to='/users'>
              <Flex
                justifyContent='flex-end'
                alignItems='center'
                gap='.3rem'
                color='blue.main'
                fontSize='1.1rem'
                fontWeight={500}
                mt='1rem'
                cursor='pointer'
              >
                {t('users-tb')}
                <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
              </Flex>
            </Link>
          </Box>
        </HStack>
        <Box bg='#fff' px='1rem' display='flex' flexDir='column'>
          <HStack alignItems='center' spacing='.5rem' flexGrow={1}>
            <CgNotes color='#407BFF' fontSize='3rem' />
            <Box>
              <Text fontSize='1.7rem' fontWeight={700}>
                {data.examCount}
              </Text>
              <Text
                color='rgba(80, 80, 80, 0.88)'
                fontSize='.7rem'
                fontWeight={400}
              >
                {t('total-num-exmas')}
              </Text>
            </Box>
          </HStack>
          <Link to='/exams'>
            <Flex
              justifyContent='flex-end'
              alignItems='center'
              gap='.3rem'
              color='blue.main'
              fontSize='1.1rem'
              fontWeight={500}
              my='1rem'
              cursor='pointer'
            >
              {t('exams-tb')}
              <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
            </Flex>
          </Link>
        </Box>
        <Box bg='#fff' px='1rem' display='flex' flexDir='column'>
          <HStack alignItems='center' spacing='.5rem' flexGrow={1}>
            <FiBox color='#407BFF' fontSize='3rem' />
            <Box>
              <Text fontSize='1.7rem' fontWeight={700}>
                {data.packageCount}
              </Text>
              <Text
                color='rgba(80, 80, 80, 0.88)'
                fontSize='.7rem'
                fontWeight={400}
              >
                {t('total-num-packs')}
              </Text>
            </Box>
          </HStack>
          <Link to='/packages'>
            <Flex
              justifyContent='flex-end'
              alignItems='center'
              gap='.3rem'
              color='blue.main'
              fontSize='1.1rem'
              fontWeight={500}
              my='1rem'
              cursor='pointer'
            >
              {t('packs-tb')}
              <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
            </Flex>
          </Link>
        </Box>
      </Grid>
      <Grid
        mt='1.5rem'
        templateColumns={['1fr', null, '3fr 1fr 1fr 1fr']}
        gap='1rem'
      >
        <HStack bg='#fff' py='.4rem'>
          <Box
            flexGrow='1'
            display='flex'
            flexDir='column'
            justifyContent='center'
            alignItems='center'
            py='1rem'
          >
            <BsFillQuestionSquareFill color='#407BFF' fontSize='2.5rem' />
            <Text fontWeight={700} fontSize='2rem'>
              {data.quetionCount}
            </Text>
            <Text color='rgba(80, 80, 80, 0.88)' fontSize='.5rem'>
              {t('total-num-qs')}
            </Text>
          </Box>
          <Divider borderColor='rgba(0, 0, 0, 0.5)' orientation='vertical' />
          <Box w='60%' px='1rem' py='.6rem'>
            <Colors />
            <HStack justifyContent='center'>
              <CircularProgress
                size='3.5rem'
                color='#438624'
                max={100}
                value={data.correctQuetionCount}
              >
                <CircularProgressLabel>
                  {data.correctQuetionCount}%
                </CircularProgressLabel>
              </CircularProgress>
              <CircularProgress
                size='3.5rem'
                color='#D20000'
                max={100}
                value={data.nonCorrectQuetionCount}
              >
                <CircularProgressLabel>
                  {data.nonCorrectQuetionCount}%
                </CircularProgressLabel>
              </CircularProgress>
              <CircularProgress
                size='3.5rem'
                color='#B3B3B7'
                max={100}
                value={data.emptyQuetionCount}
              >
                <CircularProgressLabel>
                  {data.emptyQuetionCount}%
                </CircularProgressLabel>
              </CircularProgress>
            </HStack>
            <Link to='/questions'>
              <Flex
                justifyContent='flex-end'
                alignItems='center'
                gap='.3rem'
                color='blue.main'
                fontSize='1.1rem'
                fontWeight={500}
                mt='1rem'
                cursor='pointer'
              >
                {t('qs-tb')}
                <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
              </Flex>
            </Link>
          </Box>
        </HStack>
        <Box bg='#fff' p='.5rem'>
          <Image display='block' mx='auto' src={gitftIcon} />
          <Link to='/exams'>
            <Flex
              justifyContent='flex-end'
              alignItems='center'
              gap='.3rem'
              color='blue.main'
              fontSize='1rem'
              fontWeight={500}
              mt='1rem'
              cursor='pointer'
            >
              {t('exams-tb')}
              <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
            </Flex>
          </Link>
        </Box>
        <Box bg='#fff' p='.5rem'>
          <Image display='block' mx='auto' src={squareIcon} />
          <Link to='/packages'>
            <Flex
              justifyContent='flex-end'
              alignItems='center'
              gap='.3rem'
              color='blue.main'
              fontSize='1rem'
              fontWeight={500}
              mt='1rem'
              cursor='pointer'
            >
              {t('packs-tb')}
              <AiOutlineArrowRight fontSize='1.2rem' className='rotate-180' />
            </Flex>
          </Link>
        </Box>
      </Grid>
    </>
  );
};

export default Overview;

export const Colors = () => {
  const t = useTranslate();
  return (
    <div className='colors'>
      <div className='overview-color correct'>{t('correct')}</div>
      <div className='overview-color wrong'>{t('wrong')}</div>
      <div className='overview-color empty'>{t('empty')}</div>
    </div>
  );
};
