import { useSearchParams } from 'react-router-dom';
import { useGetPackageById } from '../../hooks/packages';
import { useTranslate } from '../../languages';
import { ICreatePackage } from '../../types';
import PackageModalContent from './PackageModal';

interface EditPackageProps {
  packageId: string;
  onClose: () => void;
}

const EditPackage: React.FC<EditPackageProps> = ({ packageId, onClose }) => {
  const { data, isLoading } = useGetPackageById(packageId);
  const t = useTranslate();

  return (
    <PackageModalContent
      isLoading={isLoading}
      onClose={onClose}
      btnText={t('edit')}
      title={t('edit-pack')}
      type='edit'
      initialState={data as ICreatePackage}
    />
  );
};

export default EditPackage;
