import { Box, Button, Image, SimpleGrid } from '@chakra-ui/react';
import authImage from '../assests/images/auth.png';
import LanguagesMenu from './LanguagesMenu';
import Logo from './Logo';

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return (
    <SimpleGrid columns={[1, 1, 2]} minH='100vh' bg='#E5E5E5'>
      <Box display={['none', null, 'block']} h='100%' pos='relative'>
        <Image src={authImage} w='100%' h='100%' maxH='100vh' />
        <Box
          pos='absolute'
          top='0'
          left='0'
          p='2rem'
          zIndex={1}
          display='flex'
          w='100%'
          alignItems='center'
          justifyContent='space-between'
        >
          <Logo />
          <LanguagesMenu as={Button} />
        </Box>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Box px='1rem' w='100%' maxW='500px'>
          {children}
        </Box>
      </Box>
    </SimpleGrid>
  );
};

export default AuthWrapper;
