import './pagination.scss';
import { Box, HStack, Select, Text } from '@chakra-ui/react';
import {
  HiOutlineChevronDoubleRight,
  HiOutlineChevronDoubleLeft,
} from 'react-icons/hi';
import { ITEMS_PER_PAGE, PAGES } from '../../util/constants';
import { useTranslate } from '../../languages';

interface PaginationProps {
  activePage: number;
  numberOfPages?: number;
  setPage: (value: number) => void;
  changeItemserPerPage: (value: number) => void;
  itemsPerPage: number;
  totalCount: number;
  count: number;
}

const getPages = (curPage: number, n: number): number[] => {
  const pages: number[] = [];
  pages.push(curPage);
  if (curPage != 1) pages.push(1);
  if (curPage - 1 > 1) pages.push(curPage - 1);
  if (curPage - 2 > 1) pages.push(curPage - 2);
  if (curPage + 1 < n) pages.push(curPage + 1);
  if (curPage + 2 < n) pages.push(curPage + 2);
  if (curPage != n) pages.push(n);
  return pages.sort((a, b) => a - b);
};

const Pagination: React.FC<PaginationProps> = ({
  activePage,
  numberOfPages = 0,
  setPage,
  itemsPerPage,
  changeItemserPerPage,
  totalCount,
  count,
}) => {
  const t = useTranslate();
  if (!numberOfPages) {
    return null;
  }

  const pages = getPages(activePage, numberOfPages);
  const start = (activePage - 1) * itemsPerPage + 1,
    end = start + count - 1;

  const isRTL = document.dir === 'rtl';

  return (
    <Box
      p='20px'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      flexDir={['column', null, 'row']}
    >
      <Text color='#B3B3B7' fontSize='1rem' fontWeight={600}>
        {t('show')} {start}:{end} {t('from')} {totalCount} {t('items')}
      </Text>
      <HStack>
        <Box display='flex' columnGap='10px' alignItems='center'>
          <Select
            size='sm'
            value={itemsPerPage}
            onChange={(e) => {
              changeItemserPerPage(+e.target.value);
            }}
          >
            {PAGES.map((page) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </Select>
          <Text whiteSpace='nowrap'>{t('rows-p-page')}</Text>
        </Box>
        {numberOfPages > 1 && (
          <div className='pagination mt-5'>
            {activePage > 1 && (
              <div
                className={`pagination-item`}
                onClick={() => setPage(activePage - 1)}
              >
                <HiOutlineChevronDoubleLeft
                  rotate='90deg'
                  className={isRTL ? 'rotate-180' : ''}
                />
              </div>
            )}
            {pages.map((el) => {
              return (
                <div
                  key={el}
                  className={`pagination-item ${
                    activePage === el ? 'active' : ''
                  }`}
                  onClick={() => setPage(el)}
                >
                  {el}
                </div>
              );
            })}
            {activePage < numberOfPages && (
              <div
                className={`pagination-item ${
                  activePage === numberOfPages ? 'disabled' : ''
                }`}
                onClick={() => setPage(activePage + 1)}
              >
                <HiOutlineChevronDoubleRight
                  className={isRTL ? 'rotate-180' : ''}
                />
              </div>
            )}
          </div>
        )}
      </HStack>
    </Box>
  );
};

export default Pagination;
