import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ModalBody,
  ModalContent,
  Radio,
  RadioGroup,
  Spacer,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { API } from '../../app/api';
import ImageView from '../../components/ImageView';
import Pagination from '../../components/Pagination/Pagination';
import TableLoader from '../../components/loader/TableLoader';
import useNotification from '../../hooks/notification';
import { useMutation } from '../../hooks/use-mutation';
import { useQuery } from '../../hooks/use-query';
import { useTranslate } from '../../languages';
import { ITEMS_PER_PAGE, PARTS } from '../../util/constants';

interface Filter {
  type: 'all' | 'search';
  search?: string;
  page: number;
  partId: number;
  limit: number;
}

interface ReplaceExamQuestionModalContentProps {
  examId: number;
  oldQuestionId: number;
  langId: number;
  partId?: number;
  onSuccess: () => void;
  onCancel: () => void;
}

const ReplaceExamQuestionModalContent: React.FC<
  ReplaceExamQuestionModalContentProps
> = ({ examId, oldQuestionId, langId, partId = 1, onSuccess, onCancel }) => {
  const t = useTranslate();
  const notify = useNotification();
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    null
  );
  const [filter, setFilter] = useState<Filter>({
    type: 'all',
    search: '',
    page: 1,
    partId,
    limit: ITEMS_PER_PAGE,
  });

  const replaceQuestion = useMutation({
    mutationFn: API.replaceQuestion,
    onSuccess(data, params) {
      notify.success(t('replace-success'));
      onSuccess();
    },
    onError(error) {
      notify.error(error?.response?.data ?? 'error');
    },
  });

  const { isLoading, error, data } = useQuery({
    queryKey: [examId, filter, langId],
    async queryFn() {
      if (filter.type === 'search') {
        return await API.searchQuestions(
          filter.page,
          filter.limit,
          filter.search
        );
      } else {
        return await API.getQuestions(
          filter.page,
          filter.limit,
          langId,
          filter.partId
        );
      }
    },
  });

  const handleChange = (data: Partial<Filter>) => {
    setFilter((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setFilter({
        type: 'all',
        search: value,
        page: 1,
        partId: 1,
        limit: filter.limit,
      });
    } else {
      setFilter({
        type: 'search',
        search: value,
        page: 1,
        limit: filter.limit,
        partId: 0,
      });
    }
  };

  const handleReplaceQuestion = () => {
    if (selectedQuestionId) {
      replaceQuestion.mutate({
        examId,
        oldQuestionId,
        newQuestionId: selectedQuestionId,
      });
    }
  };

  return (
    <ModalContent maxW={'90%'} my='5'>
      <ModalBody maxH={'90vh'} overflow={'auto'} pt={0}>
        <HStack
          px='5'
          py='3'
          bg='#fff'
          mb='40px'
          spacing='15px'
          position={'sticky'}
          top='0'
          zIndex={10}
          flexWrap={'wrap'}
        >
          <RadioGroup
            color='blue.main'
            value={filter.partId.toString()}
            onChange={(value) => {
              handleChange({
                partId: +value,
              });
            }}
          >
            <HStack spacing='15px'>
              {PARTS.map((part, index) => (
                <Radio
                  key={part.id}
                  value={part.id.toString()}
                  colorScheme='yellow'
                >
                  {t('part')[index]}
                </Radio>
              ))}
            </HStack>
          </RadioGroup>
          <InputGroup maxW='300px'>
            {document.dir === 'rtl' ? (
              <InputRightElement>
                <BsSearch />
              </InputRightElement>
            ) : (
              <InputLeftElement>
                <BsSearch />
              </InputLeftElement>
            )}
            <Input
              type='text'
              ps={10}
              placeholder={t('search-q')}
              _placeholder={{ color: '#B3B3B7' }}
              variant='flushed'
              color='#B3B3B7'
              value={filter.search}
              onChange={handleSearch}
            />
          </InputGroup>
          <Spacer />
          <Button
            colorScheme='red'
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            colorScheme='blue'
            isDisabled={!selectedQuestionId}
            isLoading={replaceQuestion.isLoading}
            onClick={handleReplaceQuestion}
          >
            {t('replace')}
          </Button>
        </HStack>

        <Box bg='#fff'>
          {isLoading ? (
            <TableLoader numberOfRows={10} />
          ) : error ? (
            <Alert>
              <AlertIcon />
              {error}
            </Alert>
          ) : (
            <TableContainer overflow='auto'>
              <Table variant='simple' w='100%'>
                <Thead fontSize='1rem'>
                  <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
                    <Th>#</Th>
                    <Th w='50px'>{t('num')}</Th>
                    <Th>{t('img')}</Th>
                    <Th>{t('txt')}</Th>
                    <Th>{t('reason')}</Th>
                    <Th>{t('correct-answer')}</Th>
                  </Tr>
                </Thead>
                <Tbody fontSize='.8rem'>
                  {data?.questions.map((q, index) => (
                    <Tr key={q.questionId}>
                      <Td>
                        <Checkbox
                          borderColor='#D9D9D9'
                          isChecked={selectedQuestionId === q.questionId}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedQuestionId(q.questionId);
                            }
                          }}
                        />
                      </Td>
                      <Td textAlign='center'>
                        {(filter.page - 1) * filter.limit + index + 1}
                      </Td>
                      <Td>
                        <ImageView src={q.questionImage} maxW='3rem' />
                      </Td>
                      <Td>{q.questiontext}</Td>
                      <Td>{q.questionReasone}</Td>
                      <Td>
                        <Switch isChecked={!!q.questionCorrectAnswerId} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          <Divider
            borderBottomWidth='2px'
            borderColor='rgba(0, 0, 0, 0.4)'
            mt='2rem'
          />
          <Pagination
            activePage={filter.page}
            numberOfPages={data?.numberOfPages}
            totalCount={data?.totalCount ?? 0}
            setPage={(page) => handleChange({ page })}
            itemsPerPage={filter.limit}
            count={data?.questions.length ?? 0}
            changeItemserPerPage={(limit) => handleChange({ limit })}
          />
        </Box>
      </ModalBody>
    </ModalContent>
  );
};

export default ReplaceExamQuestionModalContent;
