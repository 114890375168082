import { Flex, Text, Box, Button, HStack, Divider } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addExamsToPackage,
  getExamsNotExistInPackage,
} from '../../../app/features/exams/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Loader from '../../../components/Loader';
import TableLoader from '../../../components/loader/TableLoader';
import Pagination from '../../../components/Pagination/Pagination';
import useNotification from '../../../hooks/notification';
import { useTranslate } from '../../../languages';
import { IInsertExamToPackage } from '../../../types';
import { ITEMS_PER_PAGE } from '../../../util/constants';
import AddExamsTable from './AddExamsTable';

interface AddExamsToPackageProps {}

const AddExamsToPackage: React.FC<AddExamsToPackageProps> = ({}) => {
  const { packageId } = useParams();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const navigate = useNavigate();
  const t = useTranslate();
  const { error, loading, loadingAddExams, numberOfPages, exams, totalCount } =
    useAppSelector((state) => {
      return {
        loading: state.exams.loading,
        error: state.exams.error,
        exams: state.exams.data,
        numberOfPages: state.exams.numberOfPages,
        totalCount: state.exams.totalCount,
        loadingAddExams: false,
      };
    });
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  const [selectedExams, setSelectedExams] = useState<IInsertExamToPackage[]>(
    []
  );

  useEffect(() => {
    setPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    dispatch(
      getExamsNotExistInPackage({
        packageId: packageId!,
        pageNumber: page,
        rowCount: itemsPerPage,
      })
    );
  }, [page, itemsPerPage]);

  const selectExam = (examId: number) => {
    setSelectedExams((prev) => [...prev, { examId, packageId: +packageId! }]);
  };

  const unSelectExam = (examId: number) => {
    setSelectedExams((prev) => prev.filter((item) => item.examId !== examId));
  };

  const isSelected = (examId: number) => {
    return !!selectedExams.find((item) => item.examId === examId);
  };

  const hanldeAddExamsToPackage = () => {
    dispatch(addExamsToPackage(selectedExams)).then((res) => {
      if (res.type === addExamsToPackage.fulfilled.type) {
        notify.success(res.payload as string);
        navigate(`/package/${packageId}/view`);
      }
    });
  };

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('select-exams-pack')} - {packageId}
        </Text>
        <Box display='flex' columnGap='20px'>
          <Button colorScheme='blue' onClick={hanldeAddExamsToPackage}>
            {loadingAddExams ? <Loader size='md' /> : t('finish-pack')}
          </Button>
        </Box>
      </Flex>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : (
          <AddExamsTable
            exams={exams}
            isSelected={isSelected}
            selectExam={selectExam}
            unSelectExam={unSelectExam}
          />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={page}
          numberOfPages={numberOfPages}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          count={exams.length}
          changeItemserPerPage={(value) => setItemsPerPage(value)}
        />
      </Box>
    </>
  );
};

export default AddExamsToPackage;
