import { Admin } from '../types';

export const getAdminFromSession = () => {
  const adminData = sessionStorage.getItem('admin');
  return adminData ? JSON.parse(adminData) : null;
};

export const saveAdminToSession = (data: Admin) => {
  const adminData = JSON.stringify(data);
  sessionStorage.setItem('admin', adminData);
};

export const removeAdminFromSeesion = () => sessionStorage.removeItem('admin');

export const getWebisiteLangFromLocalStorage = () =>
  localStorage.getItem('websiteLang');

export const setWebisiteLangToLocalStorage = (lang: 'ar' | 'en' | 'du') =>
  localStorage.setItem('websiteLang', lang);
