import api from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICreateUserData, IUserDetails, User } from '../../../types';

export const getUsers = createAsyncThunk(
  'get/users',
  async (
    { pageNumber, rowCount }: { pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: { rawCount: number; pageCount: number };
        item2: User[];
      }>(`/Admin/Select/All/Users/${pageNumber}/${rowCount}`);
      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        users: res.data.item2,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const searchForUsers = createAsyncThunk(
  'search/users',
  async (
    {
      pageNumber,
      rowCount,
      name,
    }: { pageNumber: number; rowCount: number; name: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<{
        item1: { rawCount: number; pageCount: number };
        item2: User[];
      }>(`/Admin/Select/User/By/Name/${pageNumber}/${rowCount}`, {
        userName: name,
      });
      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        users: res.data.item2,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'delete/users',
  async (userId: number, { rejectWithValue }) => {
    try {
      const res = await api.delete<string>(`/Admin/Delete/User/${userId}`);
      return {
        msg: res.data,
        userId,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createUser = createAsyncThunk(
  'create/user',
  async (
    { userData, packageId }: { userData: ICreateUserData; packageId: number },
    { rejectWithValue }
  ) => {
    try {
      const res1 = await api.post<User>(`/User/Insert/User`, userData);
      const userId = res1.data.userId;
      // inser package for that user
      const res2 = await api.post(`/User/Insert/Package/For/User`, {
        packageId,
        userId,
      });

      return {
        user: res1.data,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  'edit/user',
  async (user: IUserDetails, { rejectWithValue }) => {
    try {
      const data = [
        {
          op: 'replace',
          path: '/userCode',
          value: user.userCode,
        },
        {
          op: 'replace',
          path: '/userEmail',
          value: user.userEmail,
        },
        {
          op: 'replace',
          path: '/userName',
          value: user.userName,
        },
        {
          op: 'replace',
          path: '/userPassword',
          value: user.userPassword,
        },
      ];
      const res = await api.patch(`/User/Update/User/${user.userId}`, data);

      return user;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);
