import { Navigate } from 'react-router-dom';
import { getAdminFromSession } from '../util/storage';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const admin = getAdminFromSession();

  return admin ? children : <Navigate to='/login' />;
};

export default PrivateRoute;
