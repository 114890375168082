import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslate } from '../languages';
import { useState } from 'react';

interface QuestionAnswersProps {
  answers: {
    answerId?: number;
    answerText: string;
  }[];
  onAddAnswer: (answer: string) => void;
  onDeleteAnswer: (answerId: number) => void;
  onAnswerChange: (answerId: number, value: string) => void;
  addNewAnswerLoading?: boolean;
  questionCorrectAnswerId?: string | number;
  questionCorrectAnswerId2?: string | number;
  questionCorrectAnswerId3?: string | number;
  setQuestionData: (data: any) => void;
}

const QuestionAnswers: React.FC<QuestionAnswersProps> = ({
  answers,
  onDeleteAnswer,
  onAnswerChange,
  onAddAnswer,
  addNewAnswerLoading,
  setQuestionData,
  questionCorrectAnswerId2,
  questionCorrectAnswerId3,
  questionCorrectAnswerId,
}) => {
  const t = useTranslate();

  const correctAnswers = [
    questionCorrectAnswerId,
    questionCorrectAnswerId2,
    questionCorrectAnswerId3,
  ];

  const correctAnswersCount = correctAnswers.filter((x) => !!x).length;

  const [type, setType] = useState<'single-answer' | 'many-answers'>(
    correctAnswersCount > 1 ? 'many-answers' : 'single-answer'
  );

  return (
    <Box>
      <FormLabel>{t('answers')}</FormLabel>
      <VStack spacing={1}>
        {answers.map((answer, index) => (
          <HStack key={answer.answerId ?? index} spacing={2} w='100%'>
            <Input
              isRequired
              required
              value={answer.answerText}
              onChange={(e) =>
                onAnswerChange(answer.answerId ?? index, e.target.value)
              }
              variant='flushed'
              placeholder={`${t('option')} - ${index + 1}`}
              fontFamily='Inter'
              fontWeight='500'
              fontSize='.9rem'
              color='rgba(0, 0, 0, 1)'
              _placeholder={{
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '.9rem',
              }}
              mb='10px'
            />
            <CloseButton
              size={'sm'}
              onClick={() => onDeleteAnswer(answer.answerId ?? index)}
            />
          </HStack>
        ))}
      </VStack>
      <Button
        size='sm'
        mb='10px'
        onClick={() => onAddAnswer('')}
        isLoading={addNewAnswerLoading}
      >
        {t('add-answer')}
      </Button>
      <Box mt={2}>
        <Text fontWeight={600} fontSize={'lg'} textAlign={'center'} mb={3}>
          {t('correct-ans')}
        </Text>
        <HStack mb={3} spacing={0} justifyContent={'center'}>
          <Button
            borderRadius={0}
            colorScheme={'whatsapp'}
            isActive={type === 'single-answer'}
            onClick={() => {
              setType('single-answer');
              setQuestionData((prev: any) => ({
                ...prev,
                questionCorrectAnswerId2: undefined,
                questionCorrectAnswerId3: undefined,
              }));
            }}
          >
            {t('single-answer')}
          </Button>
          <Button
            borderRadius={0}
            colorScheme={'whatsapp'}
            isActive={type === 'many-answers'}
            onClick={() => setType('many-answers')}
          >
            {t('many-answers')}
          </Button>
        </HStack>
        {type === 'single-answer' ? (
          <Select
            mb='10px'
            placeholder={t('correct-ans')}
            value={questionCorrectAnswerId}
            onChange={(e) =>
              setQuestionData((prev: any) => ({
                ...prev,
                questionCorrectAnswerId: e.target.value,
              }))
            }
          >
            {answers.map((answer, index) => (
              <option
                key={answer.answerId ?? index}
                value={answer.answerId ?? answer.answerText}
              >
                {answer.answerText}
              </option>
            ))}
          </Select>
        ) : (
          <VStack spacing={3}>
            {answers.map((answer, index) => (
              <FormControl
                key={answer.answerId ?? index}
                display={'flex'}
                alignItems={'center'}
                gap={2}
                size={'lg'}
              >
                <Checkbox
                  isChecked={correctAnswers.includes(
                    answer.answerId ?? answer.answerText
                  )}
                  size={'lg'}
                  onChange={(e) => {
                    const answerId = answer.answerId ?? answer.answerText;
                    if (e.target.checked) {
                      let name: string | null = null;
                      if (!questionCorrectAnswerId) {
                        name = 'questionCorrectAnswerId';
                      } else if (!questionCorrectAnswerId2) {
                        name = 'questionCorrectAnswerId2';
                      } else if (!questionCorrectAnswerId3) {
                        name = 'questionCorrectAnswerId3';
                      }
                      if (name) {
                        setQuestionData((prev: any) => ({
                          ...prev,
                          [name as string]: answerId,
                        }));
                      }
                    } else {
                      let name: string | null = null;
                      if (questionCorrectAnswerId === answerId) {
                        name = 'questionCorrectAnswerId';
                      } else if (questionCorrectAnswerId2 === answerId) {
                        name = 'questionCorrectAnswerId2';
                      } else if (questionCorrectAnswerId3 === answerId) {
                        name = 'questionCorrectAnswerId3';
                      }
                      if (name) {
                        setQuestionData((prev: any) => ({
                          ...prev,
                          [name as string]: undefined,
                        }));
                      }
                    }
                  }}
                />
                <FormLabel color={'#000'}>{answer.answerText}</FormLabel>
              </FormControl>
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default QuestionAnswers;
