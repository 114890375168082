import { createSlice } from '@reduxjs/toolkit';
import { Package } from '../../../types';
import { DEFAULT_ERROR } from '../../../util/constants';
import {
  deletePackageById,
  getPackageById,
  getPackages,
  insertPackage,
  updatePackage,
} from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  numberOfPages: number;
  totalCount: number;
  data: Package[];
  currentPackage: Package | null;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  numberOfPages: 0,
  totalCount: 0,
  data: [],
  currentPackage: null,
};

const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get packages
    builder.addCase(getPackages.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
      state.data = action.payload.packages;
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // get package by id
    builder.addCase(getPackageById.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getPackageById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.currentPackage = action.payload;
    });
    builder.addCase(getPackageById.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // delete package
    builder.addCase(deletePackageById.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deletePackageById.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = state.data.filter((p) => p.packageId !== action.payload.packageId);
    });
    builder.addCase(deletePackageById.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // insert package
    builder.addCase(insertPackage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(insertPackage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = [action.payload, ...state.data];
    });
    builder.addCase(insertPackage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // update package
    builder.addCase(updatePackage.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updatePackage.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      const packageIndex = state.data.findIndex(
        (p) => p.packageId === action.payload.packageId
      );
      if (packageIndex !== -1) {
        state.data[packageIndex] = action.payload;
      }
    });
    builder.addCase(updatePackage.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default packagesSlice.reducer;
export const {} = packagesSlice.actions;
