import api from '../app/api';
import { useState, useEffect } from 'react';
import { QuestionDetails } from '../types';

const useGetQuestionById = (questionId: string) => {
  const [data, setData] = useState<QuestionDetails>({} as QuestionDetails);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await api.get(
          `/Admin/Select/Question/By/QuestionId/${questionId}`
        );
        setData(res.data?.Question[0]);
        setError('');
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data, loading, error, setData };
};

export default useGetQuestionById;
