import { createAsyncThunk } from '@reduxjs/toolkit';
import { Package, ICreatePackage } from '../../../types';
import api from '../../api';

export const getPackages = createAsyncThunk(
  'get/packages',
  async (
    {
      pageNumber,
      rowCount,
      langId,
    }: { pageNumber: number; rowCount: number; langId: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: Package[];
      }>(`/Admin/Select/All/Package/${pageNumber}/${rowCount}/${langId}`);
      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        packages: res.data.item2,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPackageById = createAsyncThunk(
  'get/package/id',
  async (packageId: number | string, { rejectWithValue }) => {
    try {
      const res = await api.get<Package>(`/Admin/Select/Package/By/${packageId}`);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePackage = createAsyncThunk(
  'update/package',
  async (data: Package, { rejectWithValue }) => {
    try {
      const res = await api.put<Package>(
        `/Admin/Update/Packages/${data.packageId}`,
        data
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePackageById = createAsyncThunk(
  'delete/package',
  async (packageId: number | string, { rejectWithValue }) => {
    try {
      const res = await api.delete<string>(`/Admin/Delete/Package/By/${packageId}`);
      return {
        msg: res.data,
        packageId,
      };
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const insertPackage = createAsyncThunk(
  'insert/package',
  async (data: ICreatePackage, { rejectWithValue }) => {
    try {
      const res = await api.post<Package>(`Admin/Insert/Package`, data);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
