import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Divider,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TableLoader from '../../components/loader/TableLoader';
import React, { useState, useEffect } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { ITEMS_PER_PAGE, LANGUAGES } from '../../util/constants';
import PackagesTable from './PackagesTable';
import { getPackages } from '../../app/features/packages/actions';
import PackageModalContent from './PackageModal';
import { useSearchParams } from 'react-router-dom';
import Languges from '../../components/Languages';
import { useTranslate } from '../../languages';

interface UsersProps {}

const Packges: React.FC<UsersProps> = ({}) => {
  const dispatch = useAppDispatch();
  const { loading, error, packages, numberOfPages, totalCount } =
    useAppSelector((state) => {
      const lastAction = state.lastAction;
      return {
        loading:
          state.packages.loading &&
          lastAction.type === getPackages.pending.type,
        error: state.packages.error,
        packages: state.packages.data,
        totalCount: state.packages.totalCount,
        numberOfPages: state.packages.numberOfPages,
      };
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [langId, setLangId] = useState(2);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const t = useTranslate();

  useEffect(() => {
    setPage(1);
  }, [langId, itemsPerPage]);

  useEffect(() => {
    dispatch(getPackages({ pageNumber: page, rowCount: itemsPerPage, langId }));
  }, [page, langId, itemsPerPage]);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('packs')}
        </Text>
        <Button colorScheme='blue' onClick={openModal}>
          {t('create-pack')}
        </Button>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <PackageModalContent
            onClose={closeModal}
            type='create'
            title={t('create-pack')}
            btnText={t('create')}
          />
        </Modal>
      </Flex>
      <HStack bg='#fff' mb='40px'>
        <Languges
          currentLangId={langId}
          changeLanguage={(langId) => setLangId(langId)}
        />
      </HStack>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : error ? (
          <Alert>
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <PackagesTable packages={packages} />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={page}
          numberOfPages={numberOfPages}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          count={packages.length}
          changeItemserPerPage={(value) => setItemsPerPage(value)}
        />
      </Box>
    </>
  );
};

export default Packges;
