import { useEffect, useState } from 'react';

interface UseQueryProps<TData> {
  queryFn: () => Promise<TData>;
  queryKey?: any[];
}

export const useQuery = <TData>({
  queryFn,
  queryKey = [],
}: UseQueryProps<TData>) => {
  const [data, setData] = useState<TData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await queryFn();
        setData(res);
      } catch (error: any) {
        setError(error?.response?.data ?? 'error');
      } finally {
        setIsLoading(false);
      }
    })();
  }, queryKey);

  return {
    isLoading,
    data,
    error,
  };
};
