import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslate } from '../languages';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getMessages } from '../app/features/messages/actions';
import { changeItemsPerPage, changePage } from '../app/features/messages/slice';
import TableLoader from '../components/loader/TableLoader';
import Pagination from '../components/Pagination/Pagination';
import Loader from '../components/Loader';

interface MessagesProps {}

const Messages: React.FC<MessagesProps> = ({}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const {
    currentPage,
    error,
    loading,
    messages,
    numberOfPages,
    totalCount,
    itemsPerPage,
  } = useAppSelector((state) => {
    return {
      numberOfPages: state.messages.numberOfPages,
      error: state.messages.error,
      loading: state.messages.loading,
      currentPage: state.messages.currentPage,
      totalCount: state.messages.totalCount,
      messages: state.messages.data,
      itemsPerPage: state.messages.itemsPerPage,
    };
  });

  useEffect(() => {
    dispatch(getMessages({ pageNumber: currentPage, rowCount: itemsPerPage }));
  }, [currentPage, itemsPerPage]);

  const setPage = (page: number) => {
    dispatch(changePage(page));
  };

  const setItemsPerPage = (num: number) => {
    dispatch(changeItemsPerPage(num));
  };

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='1rem'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('messages')}
        </Text>
      </Flex>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={numberOfPages} />
        ) : error ? (
          <Alert status='error'>
            <AlertIcon />
            {t(error)}
          </Alert>
        ) : (
          <>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{t('name')}</Th>
                    <Th>{t('email')}</Th>
                    <Th>{t('phone')}</Th>
                    <Th>{t('subject')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {messages.map((msg) => (
                    <Tr key={msg.complaintId}>
                      <Td>{msg.name}</Td>
                      <Td>{msg.email}</Td>
                      <Td>{msg.phone}</Td>
                      <Td>{msg.subject}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Divider
              borderBottomWidth='2px'
              borderColor='rgba(0, 0, 0, 0.4)'
              mt='2rem'
            />
            <Pagination
              activePage={currentPage}
              numberOfPages={numberOfPages}
              totalCount={totalCount}
              setPage={setPage}
              itemsPerPage={itemsPerPage}
              count={messages.length}
              changeItemserPerPage={setItemsPerPage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Messages;
