import { FormControl, FormLabel, Input, InputProps } from '@chakra-ui/react';

interface FlushedInput extends InputProps {
  label?: string;
}

const FlushedInput: React.FC<FlushedInput> = ({ label, ...props }) => {
  return (
    <FormControl mb='30px'>
      {label ||
        (props.placeholder && (
          <FormLabel mb={0} fontWeight={600} fontSize='md'>
            {label ?? props.placeholder}
          </FormLabel>
        ))}
      <Input
        borderColor='#7C7B7B'
        variant='flushed'
        fontWeight='500'
        fontSize='1.18rem'
        color='rgba(0, 0, 0, 1)'
        _placeholder={{
          color: 'rgba(0, 0, 0, 1)',
          fontSize: '1rem',
        }}
        {...props}
      />
    </FormControl>
  );
};

export default FlushedInput;
