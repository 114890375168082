import { Exam, ICreateExam } from '../../types';
import {
  Avatar,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  BASE_URL,
  DEFAULT_ERROR,
  FREE_EXAM,
  getLanguageName,
} from '../../util/constants';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useNotification from '../../hooks/notification';
import {
  deleteExam,
  setFreeExam,
  updateExam,
} from '../../app/features/exams/actions';
import { BiChevronRight } from 'react-icons/bi';
import { Link, useSearchParams } from 'react-router-dom';
import ExamModal from './ExamModal';
import { useTranslate } from '../../languages';

interface ExamsTableProps {
  exams: Exam[];
}

const ExamsTable: React.FC<ExamsTableProps> = ({ exams }) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const t = useTranslate();
  const [search, setSearch] = useSearchParams();
  const { loading } = useAppSelector((state) => {
    return {
      loading: state.exams.loading,
    };
  });

  const handleDeleteExam = (examId: number) => {
    if (window.confirm(t('confirm-del-e'))) {
      dispatch(deleteExam(examId))
        .then((res) => {
          if (res.type === deleteExam.fulfilled.type) {
            notify.success(t('del-success'));
          } else {
            notify.error((res.payload as any)?.msg as string);
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  const handleUpdateExam = (exam: Exam | ICreateExam) => {
    dispatch(updateExam(exam as Exam)).then((res) => {
      if (res.type === updateExam.fulfilled.type) {
        notify.success(t('upt-success'));
        setSearch({});
      } else {
        notify.error((res.payload as string) ?? t('upt-fail'));
      }
    });
  };

  const examId = search.get('examId'),
    mode = search.get('mode');

  let currentExam: Exam | undefined = undefined;
  if (examId && mode) {
    currentExam = exams.find((e) => e.examId === +examId);
  }

  return (
    <>
      <Modal isOpen={!!currentExam} onClose={() => setSearch({})}>
        <ModalOverlay />
        <ExamModal
          btnText={t('edit-exam')}
          title={t('edit-exam')}
          initialState={currentExam!}
          isLoading={loading}
          handleBtnClick={handleUpdateExam}
        />
      </Modal>
      <TableContainer overflow='auto'>
        <Table variant='simple'>
          <Thead fontSize='1rem'>
            <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
              <Th>{t('num')}</Th>
              <Th>{t('name')}</Th>
              <Th>{t('repeat-count')}</Th>
              <Th>{t('lang')}</Th>
              <Th>{t('is-free')}</Th>
              <Th>{t('exam') + t('questions')}</Th>
              <Th w='120px'>{t('actions')}</Th>
            </Tr>
          </Thead>
          <Tbody fontSize='.9rem'>
            {exams.map((e) => (
              <Tr key={e.examId}>
                <Td>{e.examNumber}</Td>
                <Td>{e.examName}</Td>
                <Td>{e.examRepeatCount}</Td>
                <Td>{getLanguageName(e.examLanguageId)}</Td>
                <Td>
                  <Switch
                    isChecked={e.examName === FREE_EXAM}
                    onChange={(ev) => {
                      if (ev.target.checked) {
                        dispatch(setFreeExam(e.examId)).then((res) => {
                          if (res.type === setFreeExam.fulfilled.type) {
                            notify.success(t('exam') + t('is-free'));
                          } else {
                            notify.error(res.payload as string);
                          }
                        });
                      }
                    }}
                  />
                </Td>
                <Td>
                  <Link to={`/exam/${e.examId}/content`}>
                    <Button
                      colorScheme='blue'
                      borderRadius={0}
                      fontSize='.8rem'
                    >
                      {t('exam-content')}
                      <BiChevronRight
                        fontSize='1.5rem'
                        className='rotate-180'
                      />
                    </Button>
                  </Link>
                </Td>
                <Td textAlign='center'>
                  <Menu placement='bottom'>
                    <MenuButton>
                      <BsThreeDotsVertical cursor='pointer' />
                    </MenuButton>
                    <MenuList
                      minW='36'
                      textAlign='center'
                      fontSize='.9rem'
                      color='#7D7D7D'
                    >
                      <MenuItem fontWeight='bold'>
                        <Link to={`/exam/${e.examId}/add-questions`}>
                          {t('add-q')}
                        </Link>
                      </MenuItem>
                      <MenuItem
                        fontWeight='bold'
                        onClick={() =>
                          setSearch({
                            mode: 'edit',
                            examId: e.examId.toString(),
                          })
                        }
                      >
                        {t('edit-exam')}
                      </MenuItem>
                      <MenuItem
                        fontWeight='bold'
                        onClick={handleDeleteExam.bind(null, e.examId)}
                      >
                        {t('del-exam')}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ExamsTable;
