export const DEFAULT_ERROR = 'error on the server';
export const ITEMS_PER_PAGE = 16;
export const BASE_URL = 'https://odayrafeh-001-site1.htempurl.com';
export const FREE_EXAM = 'Free Exam';

export const LANGUAGES = [
  {
    id: 1,
    name: 'Arabic',
    code: 'ar'
  },
  {
    id: 2,
    name: 'English',
    code: 'en'
  },
  {
    id: 3,
    name: 'Netherlands',
    code: 'du'
  },
];

export const PARTS = [
  {
    id: 1,
    name: 'Part 1',
    numberOfQuestions: 25,
  },
  {
    id: 2,
    name: 'Part 2',
    numberOfQuestions: 12,
  },
  {
    id: 3,
    name: 'Part 3',
    numberOfQuestions: 28,
  },
]

export const PAGES = [1, 2, 4, 8, 16, 32, 64];

export const getLanguageName = (langId: number) => {
  for (const lang of LANGUAGES) {
    if (lang.id === langId) return lang.name;
  }
  return 'English';
}
