import { useAppSelector } from '../app/hooks';
import ar from './ar.json';
import en from './en.json';
import du from './du.json';

interface IIndexable {
  [key: string]: any;
}

const languages: IIndexable = { ar, en, du };

export const langs = [
  {
    id: 1,
    name: 'Arabic',
    code: 'ar',
  },
  {
    id: 2,
    name: 'English',
    code: 'en',
  },
  {
    id: 3,
    name: 'Dutch',
    code: 'du',
  },
];

export const useTranslate = () => {
  const langId = useAppSelector((state) => {
    return state.content.websiteLang;
  });

  const t = (text: string) => {
    if (!langId) {
      return text;
    }
    if (langId in languages && text in languages[langId]) {
      return languages[langId][text];
    }
    return text;
  };

  return t;
};

export default languages;
