import { Box, HStack } from '@chakra-ui/react';
import { useTranslate } from '../languages';
import { LANGUAGES } from '../util/constants';

interface LangugesProps {
  currentLangId: number;
  changeLanguage: (langId: number) => void;
}

const Languges: React.FC<LangugesProps> = ({
  currentLangId,
  changeLanguage,
}) => {
  const t = useTranslate();
  return (
    <HStack>
      {LANGUAGES.map((lang) => (
        <Box
          key={lang.id}
          py='20px'
          px='15px'
          cursor='pointer'
          color='blue.main'
          fontWeight={600}
          fontSize='1rem'
          className={lang.id === currentLangId ? 'active' : ''}
          sx={{ '&.active': { borderBottom: '4px solid #09003F' } }}
          onClick={(e) => changeLanguage(lang.id)}
        >
          {t(lang.code)}
        </Box>
      ))}
    </HStack>
  );
};

export default Languges;
