import {
  Box,
  Flex,
  HStack,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  chakra,
} from '@chakra-ui/react';
import logoImage from '../assests/images/logo.png';
import {
  NavLink,
  Outlet,
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import { MdDashboard } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { BiWorld, BiLogOut } from 'react-icons/bi';
import { CgNotes } from 'react-icons/cg';
import { FiBox } from 'react-icons/fi';
import { removeAdminFromSeesion } from '../util/storage';
import { useTranslate } from '../languages';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { LANGUAGES } from '../util/constants';
import { changeWebsiteLang } from '../app/features/website-content/slice';
import { BiMessageMinus } from 'react-icons/bi';
import LanguagesMenu from './LanguagesMenu';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = ({}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { websiteLang } = useAppSelector((state) => {
    return {
      websiteLang: state.content.websiteLang,
    };
  });

  const handleLogout = () => {
    removeAdminFromSeesion();
    navigate('/login');
  };

  const path = pathname.slice(1).split('/');

  return (
    <Box h='100vh' pos='relative'>
      <Box
        w='105px'
        boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 4px 0px 8px rgba(0, 0, 0, 0.06)'
        bg='#fff'
        h='100%'
        pos='fixed'
        top='0'
        {...(document.dir === 'rtl' ? { right: 0 } : { left: 0 })}
        overflow='auto'
      >
        <List>
          <ListItem mb='30px' display='flex' justifyContent='center'>
            <Image src={logoImage} w='4.5rem' h='3.5rem' />
          </ListItem>
          <ListItem>
            <NavLink to='/overview' className='navlink'>
              <MdDashboard fontSize='1.2rem' />
              {t('dashboard')}
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink to='/users' className='navlink'>
              <HiUsers fontSize='1.2rem' />
              {t('users')}
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink to='/exams' className='navlink'>
              <CgNotes fontSize='1.2rem' />
              {t('exams')}
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink to='/questions' className='navlink'>
              <BsFillQuestionSquareFill fontSize='1.2rem' />
              {t('questions')}
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink to='/packages' className='navlink'>
              <FiBox fontSize='1.2rem' />
              {t('packs')}
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink to='/content' className='navlink'>
              <BiWorld fontSize='1.2rem' />
              {t('web-content')}
            </NavLink>
          </ListItem>
          <ListItem className='navlink'>
            <NavLink to='/messages' className='navlink'>
              <BiMessageMinus fontSize='20px' />
              {t('messages')}
            </NavLink>
          </ListItem>
          <ListItem className='navlink' onClick={handleLogout}>
            <BiLogOut fontSize='20px' fill='crimson' />
            {t('logout')}
          </ListItem>
        </List>
      </Box>
      <Box ps='105px' display='flex' flexDir='column' minH='100vh'>
        <Box
          bg='#fff'
          boxShadow='0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)'
          p='25px 15px'
          pos='sticky'
          top={0}
          left={0}
          zIndex={10}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Text color='#09003F' fontWeight={700} fontSize='1.1rem'>
            {path.map((p) => (
              <chakra.span textTransform='capitalize' key={p}>
                {t(p) + ` ${document.dir === 'rtl' ? '\\' : '/'} `}
              </chakra.span>
            ))}
          </Text>
          <Flex alignItems='center' columnGap='1rem'>
            <LanguagesMenu />
            <Box
              display='flex'
              columnGap='.3rem'
              alignItems='center'
              fontSize='1.1rem'
            >
              <BiWorld fontSize='1.4rem' />
              {t('web-title')}
            </Box>
          </Flex>
        </Box>
        <Box px='20px' py='10px' flexGrow={1} bg='gray.main' pos='relative'>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
