import { HStack, Flex, Text, Box, Alert, AlertIcon } from '@chakra-ui/react';
import Languges from '../../components/Languages';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { changeContentLang } from '../../app/features/website-content/slice';
import {
  getWebsiteContent,
  updateWebsiteContent,
} from '../../app/features/website-content/actions';
import Loader from '../../components/Loader';
import ContentCard from './ContentCard';
import { useTranslate } from '../../languages';

interface WebsiteContentProps {}

const WebsiteContent: React.FC<WebsiteContentProps> = ({}) => {
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const { langId, loading, data, error, isSaveing, item } = useAppSelector(
    (state) => {
      const lastAction = state.lastAction;
      return {
        loading:
          state.content.loading &&
          lastAction.type === getWebsiteContent.pending.type,
        isSaveing: lastAction.type === updateWebsiteContent.pending.type,
        langId: state.content.contentLangId,
        error: state.content.error,
        data: state.content.data,
        item: lastAction.arg?.path,
      };
    }
  );

  useEffect(() => {
    dispatch(getWebsiteContent(langId));
  }, [langId]);

  const changeLang = (id: number) => {
    dispatch(changeContentLang(id));
  };

  const dataArray = Object.entries(data || {});

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='1.8rem'>
          {t('web-content')}
        </Text>
      </Flex>
      <HStack bg='#fff' mb='40px'>
        <Languges currentLangId={langId} changeLanguage={changeLang} />
      </HStack>
      {loading ? (
        <Loader size='xl' />
      ) : (
        <>
          {dataArray.map(([key, value]) => (
            <ContentCard
              key={key}
              title={key}
              isLoading={isSaveing && item.includes?.(key)}
              langId={langId}
            >
              {value as string}
            </ContentCard>
          ))}
        </>
      )}
    </>
  );
};

export default WebsiteContent;
