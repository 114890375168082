import api from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ICreateQuestion,
  IInsertQuestionToExam,
  Question,
  QuestionDetails,
} from '../../../types';

export const getQuestions = createAsyncThunk(
  'get/questions',
  async (
    {
      langId,
      partId,
      pageNumber,
      rowCount,
    }: { langId: number; partId: number; pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: Question[];
      }>(
        `/Admin/Select/All/Question/${pageNumber}/${rowCount}/${langId}/${partId}`
      );
      return {
        questions: res.data.item2,
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const searchQuestions = createAsyncThunk(
  'search/questions',
  async (
    {
      text,
      pageNumber,
      rowCount,
    }: { text: string; pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: Question[];
      }>(`/Admin/Select/Question/By/QuestionName/${pageNumber}/${rowCount}`, {
        questiontext: text,
      });

      return {
        questions: res.data.item2,
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'delete/questions',
  async (questionId: number, { rejectWithValue }) => {
    try {
      const res = await api.delete(`/Admin/Delete/Question/By/${questionId}`);
      return {
        msg: res.data,
        questionId,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const createQuestion = createAsyncThunk(
  'create/question',
  async (
    { question, formData }: { question: ICreateQuestion; formData: FormData },
    { rejectWithValue }
  ) => {
    try {
      const { data: questionId } = await api.post<string>(
        `/Admin/Insert/Question`,
        question
      );

      if (formData?.get('Files')) {
        const res = await api.post(
          `/Admin/UpLoad/Question/Image/${questionId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      }

      return { questionId };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const editQuestion = createAsyncThunk(
  'edit/question',
  async (
    { data, formData }: { data: QuestionDetails; formData?: FormData | null },
    { rejectWithValue }
  ) => {
    try {
      const dataToPatch = [
        'questionCorrectAnswerId',
        'questionCorrectAnswerId2',
        'questionCorrectAnswerId3',
        'questionReasone',
        'questiontext',
      ];
      const body = dataToPatch
        .map((key) => {
          return {
            op: 'replace',
            path: `/${key}`,
            value: data[key as 'questionImage'],
          };
        })

      const res = await api.patch(
        `/Admin/Update/Question/${data.questionId}`,
        body
      );

      // console.log({ body });
      // update answers
      for (const answer of data.Answer) {
        const res = await api.patch(`/Admin/Update/Answer/${answer.answerId}`, [
          {
            op: 'replace',
            path: '/answerText',
            value: answer.answerText,
          },
        ]);
      }

      // change files
      if (formData?.get('Files')) {
        const res = await api.post<string>(
          `/Admin/UpLoad/Question/Image/${data.questionId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        data.questionImage = res.data;
      }

      return {
        ...data,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const insertQuestionToExam = createAsyncThunk(
  'exam/insert/questions',
  async (questions: IInsertQuestionToExam[], { rejectWithValue }) => {
    try {
      const res = await api.post<string>(
        `/Admin/Insert/Questions/In/Exam`,
        questions
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getQuestionsRelatedToExam = createAsyncThunk(
  'questions/exam',
  async (
    {
      examId,
      exist,
      pageNumber,
      partId,
      rowCount,
    }: {
      pageNumber: number;
      rowCount: number;
      examId: number | string;
      exist: boolean;
      partId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: any[];
      }>(
        `/Admin/Select/All/Question/In/Exam/${pageNumber}/${rowCount}/${examId}/${exist}/${partId}`
      );

      return {
        questions: res.data.item2.map(
          (q) => (q.question as Question) ?? (q as Question)
        ),
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const deleteQuestionAnswer = createAsyncThunk(
  'questions/answer/delete',
  async ({ answerId }: { answerId: number | string }, { rejectWithValue }) => {
    try {
      const res = await api.delete<string>(`/Admin/Delete/Answer/${answerId}`);

      return {
        answerId,
        message: res.data,
      };
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response?.data);
    }
  }
);

export const addAnswerToQuestion = createAsyncThunk(
  'questions/asnwer/add',
  async (
    { questionId, answer = '' }: { questionId: number; answer?: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<number>(`/Admin/Insert/Answer`, {
        answerQuestionId: questionId,
        answerText: answer,
      });
      return {
        questionId,
        answerId: res.data,
      };
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response?.data);
    }
  }
);
