import {
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

interface ImageViewProps {
  src: string;
  [key: string]: string;
}

const ImageView: React.FC<ImageViewProps> = ({ src, ...props }) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  return (
    <>
      <Image src={src} onClick={onOpen} cursor='pointer' {...props} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW='container.md'>
          <Image src={src} w='100%' />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageView;
