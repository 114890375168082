import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Divider,
  RadioGroup,
  Radio,
  useDisclosure,
  Alert,
  AlertIcon,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import {
  createExam,
  getExams,
  searchExams,
} from '../../app/features/exams/actions';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Languges from '../../components/Languages';
import TableLoader from '../../components/loader/TableLoader';
import Pagination from '../../components/Pagination/Pagination';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';
import { Exam, ICreateExam } from '../../types';
import { ITEMS_PER_PAGE, LANGUAGES } from '../../util/constants';
import ExamModal from './ExamModal';
import ExamsTable from './ExamsTable';

interface ExamsProps {}

const Exams: React.FC<ExamsProps> = ({}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const navigate = useNavigate();
  const t = useTranslate();
  const {
    loading,
    loadingCreateExam,
    numberOfPages,
    totalCount,
    exams,
    error,
  } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      error: state.exams.error,
      loading:
        state.exams.loading &&
        (lastAction.type === getExams.pending.type ||
          lastAction.type === searchExams.pending.type),
      loadingCreateExam:
        state.exams.loading && lastAction.type === createExam.pending.type,
      numberOfPages: state.exams.numberOfPages,
      totalCount: state.exams.totalCount,
      exams: state.exams.data,
    };
  });
  const [type, setType] = useState<'all' | 'search'>('all');
  const [examName, setExamName] = useState('');
  const [page, setPage] = useState(1);
  const [langId, setLangId] = useState(2);
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
  const {
    isOpen: showCreateExamModal,
    onClose: closeCreateExamModal,
    onOpen: openCreateExamModal,
  } = useDisclosure();

  useEffect(() => {
    setPage(1);
  }, [langId, itemsPerPage, type, examName]);

  useEffect(() => {
    if (type === 'all') {
      dispatch(getExams({ langId, pageNumber: page, rowCount: itemsPerPage }));
    } else {
      dispatch(
        searchExams({ pageNumber: page, rowCount: itemsPerPage, examName })
      );
    }
  }, [page, langId, itemsPerPage, examName]);

  const handleCreateExam = (exam: Exam | ICreateExam) => {
    dispatch(createExam(exam as ICreateExam)).then((res) => {
      if (res.type === createExam.fulfilled.type) {
        closeCreateExamModal();
        notify.success(t('ins-success'));
        navigate(`/exam/${(res.payload as any)?.examId}/add-questions`);
      } else {
        notify.error(res.payload as string);
      }
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setExamName(value);
    if (value === '') {
      setType('all');
    } else {
      if (type !== 'search') setType('search');
    }
  };

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('exams')}
        </Text>
        <Box display='flex' columnGap='20px'>
          <Button colorScheme='blue' onClick={openCreateExamModal}>
            {t('create-exam')}
          </Button>
          <Modal isOpen={showCreateExamModal} onClose={closeCreateExamModal}>
            <ModalOverlay />
            <ExamModal
              title={t('create-exam')}
              btnText={t('create-exam')}
              isLoading={loadingCreateExam}
              handleBtnClick={handleCreateExam}
              initialState={{
                examLanguageId: 0,
                examName: '',
                examNumber: '',
                examRepeatCount: 0,
              }}
            />
          </Modal>
        </Box>
      </Flex>
      <HStack bg='#fff' mb='40px' justifyContent='space-between'>
        <Languges
          currentLangId={langId}
          changeLanguage={(langId) => setLangId(langId)}
        />
        <InputGroup maxW='300px'>
          {document.dir === 'rtl' ? (
            <InputRightElement>
              <BsSearch />
            </InputRightElement>
          ) : (
            <InputLeftElement>
              <BsSearch />
            </InputLeftElement>
          )}
          <Input
            ps={10}
            type='search'
            placeholder={t('search-exam')}
            _placeholder={{ color: '#B3B3B7' }}
            variant='flushed'
            color='#B3B3B7'
            onChange={handleSearch}
          />
        </InputGroup>
      </HStack>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : error ? (
          <Alert>
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <ExamsTable exams={exams} />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={page}
          numberOfPages={numberOfPages}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          count={exams.length}
          changeItemserPerPage={(value) => setItemsPerPage(value)}
        />
      </Box>
    </>
  );
};

export default Exams;
