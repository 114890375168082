import { Box, Button, Divider, Flex, Text, Textarea } from '@chakra-ui/react';
import { useState } from 'react';
import { updateWebsiteContent } from '../../app/features/website-content/actions';
import { useAppDispatch } from '../../app/hooks';
import Loader from '../../components/Loader';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';

interface ContentCardProps {
  title: string;
  children: string;
  langId: number;
  isLoading: boolean;
}

const ContentCard: React.FC<ContentCardProps> = ({
  title,
  children,
  langId,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [text, setText] = useState(children);
  const toggleMode = () => {
    setMode(mode === 'view' ? 'edit' : 'view');
  };
  const t = useTranslate();

  const handleUpdate = () => {
    let path = title;
    if (path === 'footer') {
      path =
        langId === 1
          ? '/footerArabicText'
          : langId == 2
          ? '/footerEnglishText'
          : '/footerDushText';
    } else if (path === 'about') {
      path =
        langId === 1
          ? '/aboutUsArabicText'
          : langId == 2
          ? '/aboutUsEnglishText'
          : '/aboutUsDushText';
    } else {
      path = `/${title}`;
    }
    dispatch(updateWebsiteContent({ op: 'replace', path, value: text })).then(
      (res) => {
        if (res.type === updateWebsiteContent.fulfilled.type) {
          notify.success(t('upt-success'));
        } else {
          notify.error(res.payload as string);
        }
      }
    );
  };

  const handleBtnClick = () => {
    if (mode === 'edit') return handleUpdate();
    toggleMode();
  };

  return (
    <Box p='.5rem 1rem 2rem' mb='1.5rem' bg='#fff'>
      <Flex alignItems='center' justifyContent='space-between' mb='.5rem'>
        <Text color='blue.main' fontSize='1.2rem' fontWeight={500}>
          {t(title)}
        </Text>
        <Button colorScheme='blue' size='sm' onClick={handleBtnClick}>
          {isLoading ? <Loader size='md' /> : mode === 'edit' ? t('save') : t('edit')}
        </Button>
      </Flex>
      <Divider borderBottomWidth='1px' borderColor='rgba(0, 0, 0, 0.4)' />
      <Box
        my='2rem'
        fontSize='1rem'
        fontWeight={500}
        lineHeight='24px'
        color='#7D7D7D'
      >
        {mode === 'edit' ? (
          <Textarea
            variant='flushed'
            // height='fit-content'
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
        ) : (
          text
        )}
      </Box>
      <Divider borderBottomWidth='1px' borderColor='rgba(0, 0, 0, 0.4)' />
    </Box>
  );
};

export default ContentCard;
