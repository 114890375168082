import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWebsiteContentData } from '../../../types';
import api from '../../api';

interface IWebisteContent {
  contactUsId: number;
  phoneNumber: string;
  email: string;
  address: string;
  footerArabicText?: string;
  footerEnglishText?: string;
  footerDushText?: string;
  aboutUsArabicText?: string;
  aboutUsEnglishText?: string;
  aboutUsDushText?: string;
}

export const getWebsiteContent = createAsyncThunk(
  'get/website/content',
  async (langId: number, { rejectWithValue }) => {
    try {
      const res = await api.get<IWebisteContent>(
        `/User/Select/Footer/Site/And/ContactUs/${langId}`
      );
      const data = res.data;

      return {
        email: data.email,
        address: data.address,
        phoneNumber: data.phoneNumber,
        footer:
          data.footerArabicText ??
          data.footerDushText ??
          data.footerEnglishText,
        about:
          data.aboutUsArabicText ??
          data.aboutUsDushText ??
          data.aboutUsEnglishText,
      } as IWebsiteContentData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateWebsiteContent = createAsyncThunk(
  'update/website-content',
  async (
    args: { path: string; op: 'replace'; value: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.patch(
        `/Admin/Update/Footer/Site/And/ContactUs/1`,
        [args]
      );

      return data;
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response?.data);
    }
  }
);
