import {
  FormControl,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';

interface FlushedTextAreaProps extends TextareaProps {
  label?: string;
}

const FlushedTextArea: React.FC<FlushedTextAreaProps> = ({
  label,
  ...props
}) => {
  return (
    <FormControl>
      {label && (
        <FormLabel>
          {label}
        </FormLabel>
      )}
      <Textarea
        variant='flushed'
        fontFamily='Inter'
        fontWeight='500'
        fontSize='.9rem'
        color='rgba(0, 0, 0, 1)'
        _placeholder={{
          color: 'rgba(0, 0, 0, 1)',
          fontSize: '.9rem',
        }}
        {...props}
      />
    </FormControl>
  );
};

export default FlushedTextArea;
