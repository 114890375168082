import './styles/main.scss';
import { BrowserRouter as Rrouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './components/Dashboard';
import Users from './pages/users/Users';
import Packges from './pages/packges/Packages';
import Exams from './pages/exams/Exams';
import Overview from './pages/overview/Overview';
import Questions from './pages/questions/Questions';
import Exam from './pages/exam/Exam';
import AddQuestionsToExam from './pages/add-questions/AddQuestionsToExam';
import PackageExams from './pages/packges/exams/PackageExams';
import PrivateRoute from './components/PrivateRoute';
import AddExamsToPackage from './pages/packges/addExamsToPackage/AddExamsToPackage';
import WebsiteContent from './pages/website-content/WebSiteContent';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeWebsiteLang } from './app/features/website-content/slice';
import { getWebisiteLangFromLocalStorage } from './util/storage';
import Messages from './pages/Messages';
import { ErrorBoundary } from './error-boundary/ErrorBoundary';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const lang = getWebisiteLangFromLocalStorage();
    if (lang) {
      dispatch(changeWebsiteLang(lang as 'en'));
    }
  }, []);
  return (
    <ErrorBoundary>
      <Rrouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route
            path='/'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<Overview />} />
            <Route path='overview' element={<Overview />} />
            <Route path='content' element={<WebsiteContent />} />
            <Route path='messages' element={<Messages />} />
            <Route path='users' element={<Users />} />
            <Route path='packages' element={<Packges />} />
            <Route path='package/:packageId/view' element={<PackageExams />} />
            <Route
              path='package/:packageId/add-exams'
              element={<AddExamsToPackage />}
            />
            <Route path='questions' element={<Questions />} />
            <Route path='exams' element={<Exams />} />
            <Route path='exam/:examId/content' element={<Exam />} />
            <Route
              path='exam/:examId/add-questions'
              element={<AddQuestionsToExam />}
            />
          </Route>
        </Routes>
      </Rrouter>
    </ErrorBoundary>
  );
}

export default App;
