import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWebsiteContentData } from '../../../types';
import { DEFAULT_ERROR } from '../../../util/constants';
import { setWebisiteLangToLocalStorage } from '../../../util/storage';
import { getWebsiteContent, updateWebsiteContent } from './actions';

interface InitialState {
  websiteLang: 'ar' | 'en' | 'du';
  contentLangId: number;
  loading: boolean;
  error: string;
  data: IWebsiteContentData | null;
}

const initialState: InitialState = {
  websiteLang: 'en',
  contentLangId: 2,
  loading: false,
  error: '',
  data: null,
};

const websiteContentSlice = createSlice({
  name: 'websiteContent',
  initialState,
  reducers: {
    changeContentLang: (state, action: PayloadAction<number>) => {
      state.contentLangId = action.payload;
    },
    changeWebsiteLang: (state, action: PayloadAction<'en' | 'ar' | 'du'>) => {
      state.websiteLang = action.payload;
      if (state.websiteLang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }
      setWebisiteLangToLocalStorage(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebsiteContent.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getWebsiteContent.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload;
    });
    builder.addCase(getWebsiteContent.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // update webiste conent
    builder.addCase(updateWebsiteContent.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(updateWebsiteContent.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(updateWebsiteContent.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default websiteContentSlice.reducer;
export const { changeContentLang, changeWebsiteLang } =
  websiteContentSlice.actions;
