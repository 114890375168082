import { configureStore, AnyAction } from '@reduxjs/toolkit';

// reducers
import usersSlice from './features/users/slice';
import packagesSlice from './features/packages/slice';
import questionsSlice from './features/questions/slice';
import examsSlice from './features/exams/slice';
import appContentSlice from './features/website-content/slice';
import messagesSlice from './features/messages/slice';

const store = configureStore({
  reducer: {
    users: usersSlice,
    packages: packagesSlice,
    questions: questionsSlice,
    exams: examsSlice,
    messages: messagesSlice,
    content: appContentSlice,
    lastAction: (state: AnyAction | undefined, action) => {
      const arg = { ...action.meta?.arg };
      delete arg?.data;
      delete arg?.formData;
      return {
        type: action.type,
        arg,
      };
    },
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
