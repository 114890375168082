import { Box, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logoIcon from '../assests/images/logo.png';

interface LogoProps {
  imageStyle?: object;
  titleStyle?: object;
  subTitleStyle?: object;
}

const Logo: React.FC<LogoProps> = ({ imageStyle, titleStyle, subTitleStyle }) => {
  return (
    <Link to='/'>
      <Box display='flex' columnGap='10px' alignItems='center'>
        <Image src={logoIcon} {...imageStyle} />
        <Box>
          <Text color='#72C2FC' fontSize='md' mb='-4px' fontWeight={400} {...titleStyle}>
            AL-SHAHBA
          </Text>
          <Text color='#fff' fontSize='xs' fontWeight={900} {...subTitleStyle}>
            RIJ SCHOOL
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

export default Logo;
