import QuestionsTable from './QuestionsTable';
import {
  Flex,
  Text,
  Button,
  Box,
  HStack,
  Divider,
  RadioGroup,
  Radio,
  useDisclosure,
  Alert,
  AlertIcon,
  Image,
  Spacer,
  InputGroup,
  InputLeftElement,
  Input,
  Modal,
  ModalOverlay,
  InputRightElement,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TableLoader from '../../components/loader/TableLoader';
import React, { useState, useEffect } from 'react';
import Pagination from '../../components/Pagination/Pagination';
import { ITEMS_PER_PAGE, LANGUAGES, PARTS } from '../../util/constants';
import {
  createQuestion,
  getQuestions,
  searchQuestions,
} from '../../app/features/questions/actions';
import MCQModalContent from './MCQModal';
import { ICreateQuestion } from '../../types';
import useNotification from '../../hooks/notification';
import DraggableModal from './DraggableModalContent';
import Languges from '../../components/Languages';
import api from '../../app/api';
import uploadImage from '../../assests/images/upload.png';
import { useTranslate } from '../../languages';
import { BsSearch } from 'react-icons/bs';
import { clearQuestions } from '../../app/features/questions/slice';

interface QuestionsProps {}

interface IFilter {
  type: string;
  questionText: string;
  page: number;
  partId: number;
  itemsPerPage: number;
  langId: number;
}

const Questions: React.FC<QuestionsProps> = ({}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const t = useTranslate();
  const {
    loading,
    error,
    questions,
    numberOfPages,
    totalCount,
    loadingQuestion,
  } = useAppSelector((state) => {
    const lastAction = state.lastAction;
    return {
      loading:
        state.questions.loading &&
        (lastAction.type === getQuestions.pending.type ||
          lastAction.type === searchQuestions.pending.type),
      loadingQuestion: lastAction.type === createQuestion.pending.type,
      error: state.questions.error,
      questions: state.questions.data,
      numberOfPages: state.questions.numberOfPages,
      totalCount: state.questions.totalCount,
    };
  });
  const [upload, setUpload] = useState({
    isLoading: false,
    value: 0,
  });

  const filterInStoraage = sessionStorage.getItem('questions-filter');

  const [filter, setFilter] = useState<IFilter>(
    filterInStoraage
      ? JSON.parse(filterInStoraage)
      : {
          type: 'all',
          questionText: '',
          page: 1,
          partId: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          langId: 2,
        }
  );
  const {
    isOpen: showMCQModal,
    onClose: closeMCQModal,
    onOpen: openMCQModal,
  } = useDisclosure();
  const {
    isOpen: showDraggableModal,
    onClose: closeDraggableModal,
    onOpen: openDraggableModal,
  } = useDisclosure();

  useEffect(() => {
    if (filter.type === 'all') {
      dispatch(
        getQuestions({
          langId: filter.langId,
          partId: filter.partId,
          pageNumber: filter.page,
          rowCount: filter.itemsPerPage,
        })
      );
    } else {
      dispatch(
        searchQuestions({
          text: filter.questionText,
          pageNumber: filter.page,
          rowCount: filter.itemsPerPage,
        })
      );
    }

    sessionStorage.setItem('questions-filter', JSON.stringify(filter));

    return () => {
      dispatch(clearQuestions());
    }
  }, [filter, upload.isLoading]);

  const handleCreateQuestion = async (
    question: ICreateQuestion,
    image: File | null
  ) => {
    const formData = new FormData();
    if (image) {
      formData.append('Files', image);
    }
    dispatch(createQuestion({ question, formData })).then((res) => {
      if (res.type === createQuestion.fulfilled.type) {
        notify.success(t('ins-success'));
        closeDraggableModal();
        closeMCQModal();
      } else {
        notify.error(res.payload as string);
      }
    });
  };

  const handleSelectFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      const data = new FormData();
      data.append('formFile', file);
      handleUploadFile(data);
    };
    input.click();
  };

  const handleUploadFile = async (data: FormData) => {
    try {
      setUpload((prev) => {
        return { ...prev, isLoading: true };
      });
      const res = await api.post<string>(
        `/InsertingFileExel/Insert/ExelSheet`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.round(
              (progressEvent.loaded / (progressEvent.total ?? 1)) * 100
            );
            setUpload((prev) => {
              return {
                ...prev,
                value: uploadPercentage,
              };
            });
          },
        }
      );
      notify.success(res.data);
    } catch (err: any) {
      notify.error(err.response?.data);
    } finally {
      setUpload({ isLoading: false, value: 0 });
    }
  };

  const setPage = (page: number) => {
    setFilter((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const setLangId = (langId: number) => {
    setFilter((prev) => {
      return {
        ...prev,
        type: 'all',
        partId: 1,
        langId,
        questionText: '',
      };
    });
  };

  const setItemsPerPage = (items: number) => {
    setFilter((prev) => {
      return {
        ...prev,
        itemsPerPage: items,
      };
    });
  };

  const setPartId = (partId: number) => {
    setFilter((prev) => {
      return {
        ...prev,
        type: 'all',
        partId,
        questionText: '',
      };
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setFilter({
        type: 'all',
        questionText: value,
        page: 1,
        partId: 1,
        itemsPerPage: filter.itemsPerPage,
        langId: 2,
      });
    } else {
      setFilter({
        type: 'search',
        questionText: value,
        page: 1,
        itemsPerPage: filter.itemsPerPage,
        langId: 0,
        partId: 0,
      });
    }
  };

  if (upload.isLoading) {
    return (
      <Box
        pos='absolute'
        top='45%'
        left='45%'
        transform='translate(-50%, -50%)'
        textAlign='center'
        h='fit-content'
      >
        <Text color='#407BFF' fontWeight={700} fontSize='1.68rem'>
          {upload.value}%
        </Text>
        <Image src={uploadImage} />
        <Text color='#09003F' fontWeight={600} fontSize='1.26rem'>
          {t('file-uploading')}
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Flex
        alignItems={[null, null, 'center']}
        justifyContent='space-between'
        flexDir={['column', null, 'row']}
        my='10px'
      >
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('questions')}
        </Text>
        <Box display='flex' columnGap='20px'>
          <Button colorScheme='blue' onClick={openMCQModal}>
            {t('create-mcq')}
          </Button>
          <Modal isOpen={showMCQModal} onClose={closeMCQModal}>
            <ModalOverlay />
            <MCQModalContent
              isLoading={loadingQuestion}
              title={t('create-choose-q')}
              btnText={t('create')}
              handleBtnClick={handleCreateQuestion}
              initialState={{
                answers: [
                  { answerText: '' },
                  { answerText: '' },
                ],
                questionCorrectAnswerId: '',
                questionImage: '',
                questionIsDraggable: false,
                questionNumber: '',
                questionReasone: '',
                questiontext: '',
                questionLanguageId: null,
                questionDeptId: null,
              }}
            />
          </Modal>
          <Button colorScheme='blue' onClick={openDraggableModal}>
            {t('create-drag-q')}
          </Button>
          <Modal isOpen={showDraggableModal} onClose={closeDraggableModal}>
            <ModalOverlay />
            <DraggableModal
              type='add'
              btnText={t('create')}
              handleBtnClick={handleCreateQuestion}
              isLoading={loadingQuestion}
              title={t('create-drag-q')}
              initialState={{
                answers: [{ answerText: '[]' }],
                questionCorrectAnswerId: '',
                questionImage: '',
                questionIsDraggable: true,
                questionNumber: '',
                questionReasone: '',
                questiontext: '',
                questionLanguageId: null,
                questionDeptId: null,
              }}
            />
          </Modal>
          <Button colorScheme='blue' onClick={handleSelectFile}>
            {t('imp-excel')}
          </Button>
        </Box>
      </Flex>
      <HStack bg='#fff' mb='40px' spacing='15px'>
        <Languges currentLangId={filter.langId} changeLanguage={setLangId} />
        <Divider
          orientation='vertical'
          h='25px'
          borderColor='solid rgba(0, 0, 0, 0.5)'
        />
        <RadioGroup
          color='blue.main'
          value={filter.partId.toString()}
          onChange={(value) => {
            setPartId(+value);
          }}
        >
          <HStack spacing='15px'>
            {PARTS.map((part, index) => (
              <Radio
                key={part.id}
                value={part.id.toString()}
                colorScheme='yellow'
              >
                {t('part')[index]}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
        <Spacer />
        <InputGroup maxW='300px'>
          {document.dir === 'rtl' ? (
            <InputRightElement>
              <BsSearch />
            </InputRightElement>
          ) : (
            <InputLeftElement>
              <BsSearch />
            </InputLeftElement>
          )}
          <Input
            type='text'
            ps={10}
            placeholder={t('search-q')}
            _placeholder={{ color: '#B3B3B7' }}
            variant='flushed'
            color='#B3B3B7'
            value={filter.questionText}
            onChange={handleSearch}
          />
        </InputGroup>
      </HStack>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={filter.itemsPerPage} />
        ) : error ? (
          <Alert>
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <QuestionsTable
            questions={questions}
            firstItemIndex={(filter.page - 1) * filter.itemsPerPage}
          />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={filter.page}
          numberOfPages={numberOfPages}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={filter.itemsPerPage}
          count={questions.length}
          changeItemserPerPage={setItemsPerPage}
        />
      </Box>
    </>
  );
};

export default Questions;
