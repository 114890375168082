import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { deleteUser } from '../../app/features/users/actions';
import { useAppDispatch } from '../../app/hooks';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';
import { User } from '../../types';
import { BASE_URL, DEFAULT_ERROR } from '../../util/constants';
import EditUserModalContent from './EditUserModal';

interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const t = useTranslate();
  const [search, setSearch] = useSearchParams();

  const handleDeleteUser = (userId: number) => {
    if (window.confirm(t('confirm-del-user'))) {
      dispatch(deleteUser(userId))
        .then((res) => {
          if (res.type === deleteUser.fulfilled.type) {
            notify.success(t('del-success'));
          } else {
            notify.error(res.payload as string);
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  const userId = search.get('userId');

  return (
    <>
      <Modal isOpen={!!userId} onClose={() => setSearch({})}>
        <ModalOverlay />
        <EditUserModalContent userId={userId!} onClose={() => setSearch({})} />
      </Modal>
      <TableContainer overflow='auto'>
        <Table variant='simple' fontSize='1rem'>
          <Thead>
            <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
              <Th py='20px' fontSize='1.1rem' w='150px'>
                {t('img')}
              </Th>
              <Th py='20px' fontSize='1.1rem'>
                {t('username')}
              </Th>
              <Th py='20px' fontSize='1.1rem'>
                {t('email')}
              </Th>
              <Th py='20px' fontSize='1.1rem' w='120px'>
                {t('actions')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user, index) => (
              <Tr key={user.userId}>
                <Td textAlign='center'>
                  <Avatar src={BASE_URL + user.userImage} size='sm' />
                </Td>
                <Td>{user.userName}</Td>
                <Td>{user.userEmail}</Td>
                <Td textAlign='center'>
                  <Menu placement='bottom'>
                    <MenuButton>
                      <BsThreeDotsVertical cursor='pointer' />
                    </MenuButton>
                    <MenuList
                      minW='36'
                      textAlign='center'
                      fontSize='.9rem'
                      color='#7D7D7D'
                    >
                      <MenuItem
                        fontWeight='bold'
                        onClick={() =>
                          setSearch({ userId: user.userId.toString() })
                        }
                      >
                        {t('edit')}
                      </MenuItem>
                      <MenuItem
                        fontWeight='bold'
                        onClick={handleDeleteUser.bind(null, user.userId)}
                      >
                        {t('del-user')}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UsersTable;
