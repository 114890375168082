import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { deleteQuestion } from '../../app/features/questions/actions';
import { useAppDispatch } from '../../app/hooks';
import ImageView from '../../components/ImageView';
import useNotification from '../../hooks/notification';
import { useTranslate } from '../../languages';
import { Question } from '../../types';
import { DEFAULT_ERROR } from '../../util/constants';
import EditQuestion from './edit/EditQuestion';

interface QuestionAction {
  name: string;
  onClick: (question: Question) => void;
}

interface QuestionsTableProps {
  questions: Question[];
  firstItemIndex: number;
  actions?: QuestionAction[];
}

const QuestionsTable: React.FC<QuestionsTableProps> = ({
  questions,
  firstItemIndex,
  actions,
}) => {
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const t = useTranslate();
  const [search, setSearch] = useSearchParams();

  const handleDeleteQuestion = (questionsId: number) => {
    if (window.confirm(t('del-q-msg'))) {
      dispatch(deleteQuestion(questionsId))
        .then((res) => {
          if (res.type === deleteQuestion.fulfilled.type) {
            notify.success(t('del-success'));
          } else {
            notify.error(t('del-fail'));
          }
        })
        .catch((err) => {
          notify.error(DEFAULT_ERROR);
        });
    }
  };

  const mode = search.get('mode'),
    questionType = search.get('questionType'),
    questionId = search.get('questionId');

  const closeEditModal = () => setSearch({});

  const defaultActions: QuestionAction[] = [
    {
      name: 'edit-q',
      onClick(question) {
        setSearch({
          mode: 'edit',
          questionId: question.questionId.toString(),
          questionType: question.questionIsDraggable ? 'drag' : 'mcq',
        });
      },
    },
    {
      name: 'del-q',
      onClick(question) {
        handleDeleteQuestion(question.questionId);
      },
    },
  ];

  return (
    <>
      <Modal
        isOpen={!!questionId && !!questionType && mode === 'edit'}
        onClose={closeEditModal}
      >
        <ModalOverlay />
        <EditQuestion
          onClose={closeEditModal}
          questionId={questionId!}
          type={questionType!}
        />
      </Modal>
      <TableContainer overflow='auto'>
        <Table variant='simple' w='100%'>
          <Thead fontSize='1rem'>
            <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
              <Th w='50px'>{t('num')}</Th>
              <Th>{t('img')}</Th>
              <Th>{t('txt')}</Th>
              <Th>{t('reason')}</Th>
              <Th>{t('correct-answer')}</Th>
              <Th textAlign='center'>{t('actions')}</Th>
            </Tr>
          </Thead>
          <Tbody fontSize='.8rem'>
            {questions.map((q, index) => (
              <Tr key={q.questionId}>
                <Td textAlign='center'>{firstItemIndex + index + 1}</Td>
                <Td>
                  <ImageView src={q.questionImage} maxW='3rem' />
                </Td>
                <Td>{q.questiontext}</Td>
                <Td>{q.questionReasone}</Td>
                <Td>
                  <Switch isChecked={!!q.questionCorrectAnswerId} />
                </Td>
                <Td textAlign='center'>
                  <Menu placement='bottom'>
                    <MenuButton>
                      <BsThreeDotsVertical cursor='pointer' />
                    </MenuButton>
                    <MenuList
                      minW='36'
                      textAlign='center'
                      fontSize='.9rem'
                      color='#7D7D7D'
                    >
                      {actions?.map((action, index) => (
                        <MenuItem
                          key={index}
                          fontWeight='bold'
                          onClick={() => action.onClick(q)}
                        >
                          {t(action.name)}
                        </MenuItem>
                      ))}
                      {defaultActions?.map((action, index) => (
                        <MenuItem
                          key={index}
                          fontWeight='bold'
                          onClick={() => action.onClick(q)}
                        >
                          {t(action.name)}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuestionsTable;
