import { createAsyncThunk } from '@reduxjs/toolkit';
import { Message } from '../../../types';
import api from '../../api';

export const getMessages = createAsyncThunk(
  'get/messages',
  async (
    { pageNumber, rowCount }: { pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.get<{
        item1: { rawCount: number; pageCount: number };
        item2: Message[];
      }>(`/Admin/Select/All/Complaint/${pageNumber}/${rowCount}`);

      return {
        data: data.item2,
        currentPage: pageNumber,
        totalCount: data.item1.rawCount,
        numberOfPages: data.item1.pageCount,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);
