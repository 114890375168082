import { Exam } from '../../../types';
import {
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useTranslate } from '../../../languages';

interface AddExamsTableProps {
  exams: Exam[];
  selectExam: (examId: number) => void;
  unSelectExam: (examId: number) => void;
  isSelected: (examId: number) => boolean;
}

const AddExamsTable: React.FC<AddExamsTableProps> = ({
  exams,
  unSelectExam,
  selectExam,
  isSelected,
}) => {

  const t = useTranslate();
  
  return (
    <TableContainer overflow='auto'>
      <Table variant='simple' w='100%'>
        <Thead fontSize='1rem'>
          <Tr borderColor='rgba(0, 0, 0, 0.4)' borderBottomWidth='2px'>
            <Th>{t('selected')}</Th>
            <Th>{t('num')}</Th>
            <Th>{t('name')}</Th>
            <Th>{t('repeat-count')}</Th>
            <Th>{t('lang')}</Th>
            {/* <Th w='120px'>actions</Th> */}
          </Tr>
        </Thead>
        <Tbody fontSize='.9rem'>
          {exams.map((e) => (
            <Tr key={e.examId}>
              <Td textAlign='center'>
                <Checkbox
                  isChecked={isSelected(e.examId)}
                  onChange={(ev) => {
                    if (ev.target.checked) {
                      selectExam(e.examId);
                    } else {
                      unSelectExam(e.examId);
                    }
                  }}
                />
              </Td>
              <Td>{e.examNumber}</Td>
              <Td>{e.examName}</Td>
              <Td>{e.examRepeatCount}</Td>
              <Td>{e.examLanguageId}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AddExamsTable;
