import {
  Flex,
  Text,
  Button,
  Box,
  Input,
  InputGroup,
  HStack,
  InputLeftElement,
  Divider,
  InputRightElement,
  Modal,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs';
import CreateUserModalContent from './CreateUserModal';
import UsersTable from './UsersTable';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TableLoader from '../../components/loader/TableLoader';
import React, { useState, useEffect } from 'react';
import { getUsers, searchForUsers } from '../../app/features/users/actions';
import Pagination from '../../components/Pagination/Pagination';
import { ITEMS_PER_PAGE } from '../../util/constants';
import { useTranslate } from '../../languages';

interface UsersProps {}

const Users: React.FC<UsersProps> = ({}) => {
  const dispatch = useAppDispatch();
  const { loading, error, users, numberOfPages, totalCount } = useAppSelector(
    (state) => {
      const lastActionType = state.lastAction.type;
      return {
        loading:
          state.users.loading &&
          [getUsers.pending.type, searchForUsers.pending.type].includes(
            lastActionType
          ),
        error: state.users.error,
        users: state.users.data,
        totalCount: state.users.totalCount,
        numberOfPages: state.users.numberOfPages,
      };
    }
  );
  const t = useTranslate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<'all' | 'search'>('all');
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

  useEffect(() => {
    setPage(1);
  }, [type, itemsPerPage]);

  useEffect(() => {
    if (type == 'all') {
      dispatch(getUsers({ pageNumber: page, rowCount: itemsPerPage }));
    } else if (type == 'search') {
      dispatch(
        searchForUsers({
          pageNumber: page,
          rowCount: itemsPerPage,
          name: searchName,
        })
      );
    }
  }, [page, itemsPerPage, searchName]);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchName(value);
    if (value === '') {
      setType('all');
    } else {
      if (type !== 'search') setType('search');
    }
  };

  return (
    <>
      <Flex alignItems='center' justifyContent='space-between' my='10 px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('users')}
        </Text>
        <Button colorScheme='blue' onClick={openModal}>
          {t('create-user')}
        </Button>
        <Modal onClose={closeModal} isOpen={isModalOpen}>
          <ModalOverlay />
          <CreateUserModalContent onClose={closeModal} />
        </Modal>
      </Flex>
      <HStack bg='#fff' py='20px' px='15px' mb='40px'>
        <InputGroup maxW='350px'>
          {document.dir === 'rtl' ? (
            <InputRightElement>
              <BsSearch />
            </InputRightElement>
          ) : (
            <InputLeftElement>
              <BsSearch />
            </InputLeftElement>
          )}
          <Input
            type='search'
            ps='2.5rem'
            placeholder={t('search-user')}
            _placeholder={{ color: '#B3B3B7' }}
            variant='flushed'
            color='#B3B3B7'
            onChange={handleSearch}
          />
        </InputGroup>
      </HStack>
      <Box bg='#fff'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : (
          <UsersTable users={users} />
        )}
        <Divider
          borderBottomWidth='2px'
          borderColor='rgba(0, 0, 0, 0.4)'
          mt='2rem'
        />
        <Pagination
          activePage={page}
          numberOfPages={numberOfPages}
          totalCount={totalCount}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          count={users.length}
          changeItemserPerPage={(value) => setItemsPerPage(value)}
        />
      </Box>
    </>
  );
};

export default Users;
