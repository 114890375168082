import { createSlice } from '@reduxjs/toolkit';
import { Question } from '../../../types';
import { DEFAULT_ERROR } from '../../../util/constants';
import {
  getQuestions,
  deleteQuestion,
  createQuestion,
  insertQuestionToExam,
  editQuestion,
  deleteQuestionAnswer,
  addAnswerToQuestion,
  searchQuestions,
  getQuestionsRelatedToExam,
} from './actions';

interface InitialState {
  loading: boolean;
  error: string;
  numberOfPages: number;
  totalCount: number;
  data: Question[];
}

const initialState: InitialState = {
  loading: false,
  error: '',
  numberOfPages: 0,
  totalCount: 0,
  data: [],
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    clearQuestions: (state) => {
      state.data = [];
    }
  },
  extraReducers: (builder) => {
    // get all questions
    builder.addCase(getQuestions.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.questions;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getQuestions.rejected, (state, action) => {
      state.numberOfPages = 0;
      state.totalCount = 0;
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // search questions by text
    builder.addCase(searchQuestions.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(searchQuestions.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.questions;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(searchQuestions.rejected, (state, action) => {
      state.numberOfPages = 0;
      state.totalCount = 0;
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // delete question by id
    builder.addCase(deleteQuestion.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deleteQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = state.data.filter(
        (q) => q.questionId !== action.payload.questionId
      );
    });
    builder.addCase(deleteQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // create question
    builder.addCase(createQuestion.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(createQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(createQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // insert questions to exams
    builder.addCase(insertQuestionToExam.pending, (state, action) => {
      // state.loading = true;
      state.error = '';
    });
    builder.addCase(insertQuestionToExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(insertQuestionToExam.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // get questions in/out exam
    builder.addCase(getQuestionsRelatedToExam.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(getQuestionsRelatedToExam.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.data = action.payload.questions;
      state.numberOfPages = action.payload.numberOfPages;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getQuestionsRelatedToExam.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // edit question
    builder.addCase(editQuestion.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(editQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      const questionIndex = state.data.findIndex(
        (q) => q.questionId === action.payload.questionId
      );
      if (questionIndex != -1) {
        state.data[questionIndex].questiontext = action.payload.questiontext;
        state.data[questionIndex].questionImage = action.payload.questionImage;
        state.data[questionIndex].questionReasone =
          action.payload.questionReasone;
      }
    });
    builder.addCase(editQuestion.rejected, (state, action) => {
      state.loading = false;
      // state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // delete quesiton answer
    builder.addCase(deleteQuestionAnswer.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(deleteQuestionAnswer.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteQuestionAnswer.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });

    // add answer to question
    builder.addCase(addAnswerToQuestion.pending, (state, action) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(addAnswerToQuestion.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(addAnswerToQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as string) || DEFAULT_ERROR;
    });
  },
});

export default questionsSlice.reducer;
export const { clearQuestions } = questionsSlice.actions;
