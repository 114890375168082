import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Modal,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getExamById } from '../../app/features/exams/actions';
import { getQuestionsRelatedToExam } from '../../app/features/questions/actions';
import { clearQuestions } from '../../app/features/questions/slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import TableLoader from '../../components/loader/TableLoader';
import { useTranslate } from '../../languages';
import { PARTS } from '../../util/constants';
import QuestionsTable from '../questions/QuestionsTable';
import ReplaceExamQuestionModalContent from '../questions/replace-question-modal';
import ExamParts from '../../components/exam-parts';



interface ExamProps {}

const Exam: React.FC<ExamProps> = ({}) => {
  const { examId } = useParams();
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const [replaceQuestionId, setReplaceQuestionId] = useState<number | null>(null);
  const { loading, error, questions, currentExam } = useAppSelector((state) => {
    return {
      loading: state.questions.loading,
      error: state.questions.error,
      questions: state.questions.data,
      currentExam: state.exams.currentExam,
    };
  });
  const [partId, setPartId] = useState(1);
  const itemsPerPage = PARTS[partId - 1].numberOfQuestions;

  const replaceQuestionModal = useDisclosure();

  useEffect(() => {
    dispatch(getExamById(+examId!));

    dispatch(
      getQuestionsRelatedToExam({
        pageNumber: 1,
        rowCount: itemsPerPage,
        examId: examId!,
        exist: true,
        partId,
      })
    );

    return () => {
      dispatch(clearQuestions());
    };
  }, [partId]);

  return (
    <>
      {currentExam && (
        <Modal
          isOpen={replaceQuestionModal.isOpen}
          onClose={replaceQuestionModal.onClose}
        >
          <ModalOverlay />
          <ReplaceExamQuestionModalContent
            examId={currentExam.examId}
            oldQuestionId={replaceQuestionId!}
            langId={currentExam.examLanguageId}
            partId={partId}
            onCancel={replaceQuestionModal.onClose}
            onSuccess={() => {
              replaceQuestionModal.onClose();
              dispatch(
                getQuestionsRelatedToExam({
                  pageNumber: 1,
                  rowCount: itemsPerPage,
                  examId: examId!,
                  exist: true,
                  partId,
                })
              );
            }}
          />
        </Modal>
      )}
      <Flex alignItems='center' justifyContent='space-between' my='10px'>
        <Text color='blue.main' fontWeight={700} fontSize='2rem'>
          {t('exam')} - {examId}
        </Text>
      </Flex>
      <ExamParts 
        partId={partId}
        setPartId={(partId) => setPartId(partId)}
      />
      <Box bg='#fff' mb='20px'>
        {loading ? (
          <TableLoader numberOfRows={itemsPerPage} />
        ) : error ? (
          <Alert>
            <AlertIcon />
            {error}
          </Alert>
        ) : null}
        <QuestionsTable
          questions={questions}
          firstItemIndex={0}
          actions={[
            {
              name: 'replace-q',
              onClick(question) {
                setReplaceQuestionId(question.questionId);
                replaceQuestionModal.onOpen();
              },
            },
          ]}
        />
      </Box>
    </>
  );
};

export default Exam;
