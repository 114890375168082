import axios from 'axios';
import { Question } from '../types';

const api = axios.create({
  baseURL: 'https://odayrafeh-001-site1.htempurl.com/api',
});

export default api;

export const API = {
  getQuestions: async (
    page: number,
    count: number,
    langId: number,
    partId: number
  ) => {
    const res = await api.get<{
      item1: {
        rawCount: number;
        pageCount: number;
      };
      item2: Question[];
    }>(`/Admin/Select/All/Question/${page}/${count}/${langId}/${partId}`);
    return {
      questions: res.data.item2,
      numberOfPages: res.data.item1.pageCount,
      totalCount: res.data.item1.rawCount,
    };
  },
  searchQuestions: async (page: number, count: number, search: string = '') => {
    const res = await api.post<{
      item1: {
        rawCount: number;
        pageCount: number;
      };
      item2: Question[];
    }>(`/Admin/Select/Question/By/QuestionName/${page}/${count}`, {
      questiontext: search,
    });

    return {
      questions: res.data.item2,
      numberOfPages: res.data.item1.pageCount,
      totalCount: res.data.item1.rawCount,
    };
  },

  replaceQuestion: async ({ examId, oldQuestionId, newQuestionId}: {
    examId: number;
    oldQuestionId: number;
    newQuestionId: number;
  }) => {
    const res = await api.patch(
      `/Admin/Replace/Question/${examId}/${oldQuestionId}/${newQuestionId}`
    );
    console.log('replace question', res.data);
    return res.data;
  },
};
