import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { changeWebsiteLang } from '../app/features/website-content/slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useTranslate } from '../languages';
import { LANGUAGES } from '../util/constants';

interface LanguagesMenuProps {
  as?: any;
}

const LanguagesMenu: React.FC<LanguagesMenuProps> = ({ as }) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { websiteLang } = useAppSelector((state) => {
    return {
      websiteLang: state.content.websiteLang,
    };
  });
  return (
    <Menu placement='bottom'>
      <MenuButton as={as}>{t(websiteLang)}</MenuButton>
      <MenuList minW='36' textAlign='center' fontSize='.9rem' color='#7D7D7D'>
        {LANGUAGES.map((lang) => (
          <MenuItem
            key={lang.id}
            value={lang.code}
            onClick={() => {
              dispatch(changeWebsiteLang(lang.code as 'en'));
            }}
            fontWeight='bold'
          >
            {t(lang.code)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguagesMenu;
