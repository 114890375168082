import api from '../../api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Exam, ICreateExam, IInsertExamToPackage } from '../../../types';

export const getExams = createAsyncThunk(
  'get/exams',
  async (
    {
      langId,
      pageNumber,
      rowCount,
    }: { langId: number; pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: Exam[];
      }>(`/Admin/Select/All/Exam/${pageNumber}/${rowCount}/${langId}`);

      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        exams: res.data.item2,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const searchExams = createAsyncThunk(
  'search/exams',
  async (
    {
      pageNumber,
      rowCount,
      examName,
    }: { pageNumber: number; rowCount: number; examName: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.post<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: Exam[];
      }>(`/Admin/Select/Exam/By/examName/${pageNumber}/${rowCount}`, {
        examName,
      });

      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        exams: res.data.item2,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const createExam = createAsyncThunk(
  'create/exam',
  async (data: ICreateExam, { rejectWithValue }) => {
    try {
      const res = await api.post<Exam>(`/Admin/Insert/Exam`, data);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const updateExam = createAsyncThunk(
  'update/exam',
  async (data: Exam, { rejectWithValue }) => {
    try {
      const res = await api.put<Exam>(
        `/Admin/Update/Exam/By/${data.examId}`,
        data
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const deleteExam = createAsyncThunk(
  'delete/exam',
  async (examId: number, { rejectWithValue }) => {
    try {
      const res = await api.delete<string>(`/Admin/Delete/Exam/By/${examId}`);
      return {
        msg: res.data,
        examId,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getExamsNotExistInPackage = createAsyncThunk(
  'get/exams/not-in-package',
  async (
    {
      packageId,
      pageNumber,
      rowCount,
    }: { packageId: string; pageNumber: number; rowCount: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await api.get<{
        item1: {
          rawCount: number;
          pageCount: number;
        };
        item2: any[];
      }>(
        `/Admin/Select/Exam/Not/Exist/In/Package/${pageNumber}/${rowCount}/${packageId}`
      );
      console.log(res.data);
      return {
        numberOfPages: res.data.item1.pageCount,
        totalCount: res.data.item1.rawCount,
        exams: res.data.item2.map((item) => item.question ?? item),
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const addExamsToPackage = createAsyncThunk(
  'add/exams/package',
  async (exams: IInsertExamToPackage[], { rejectWithValue }) => {
    try {
      const res = await api.post<string>(
        `/Admin/Insert/Exam/For/Package`,
        exams
      );
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const setFreeExam = createAsyncThunk(
  'set/free-exam',
  async (examId: number | string, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/Admin/Insert/Free/Exam/${examId}`);
      if (data === ' Exist Allready Free Exam With This Language ') {
        return rejectWithValue('Exist Allready Free Exam With This Language');
      }

      return {
        examId,
        msg: data,
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);

export const getExamById = createAsyncThunk(
  'get/exam/:id',
  async (examId: number, { rejectWithValue }) => {
    try {
      const res = await api.get<Exam>(`/Admin/Select/Exam/By/${examId}`);
      return res.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data);
    }
  }
);