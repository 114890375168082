import {
  HStack,
  Input,
  ModalBody,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  Select,
  Flex,
  Button,
  FormControl,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import { LANGUAGES, PARTS } from '../../util/constants';
import { ChangeEvent, useState } from 'react';
import { ICreateQuestion } from '../../types';
import { useTranslate } from '../../languages';
import FlushedTextArea from '../../components/inputs';
import QuestionAnswers from '../../components/QuestionAnswers';

interface MCAModalContentProps {
  title: string;
  btnText: string;
  handleBtnClick: (question: ICreateQuestion, image: File | null) => void;
  initialState: ICreateQuestion;
  isLoading: boolean;
}

const MCAModalContent: React.FC<MCAModalContentProps> = ({
  title,
  btnText,
  handleBtnClick,
  initialState,
  isLoading,
}) => {
  const [questionData, setQuestionData] = useState(initialState);
  const [image, setImage] = useState<File | null>(null);
  const t = useTranslate();

  const handleInputChange = (
    e:
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLInputElement>
  ) => {
    const name = e.target.name;
    let value: string | number = e.target.value;
    if (name === 'questionDeptId' || name === 'questionLanguageId') {
      value = +value;
    }
    setQuestionData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleBtnClick(questionData, image);
  };

  const handleAddAnswer = () => {
    setQuestionData((prev) => {
      return {
        ...prev,
        answers: [...prev.answers, { answerText: '' }],
      };
    });
  };

  const correctAnswers = [];

  if (questionData.questionCorrectAnswerId) {
    correctAnswers.push(questionData.questionCorrectAnswerId);
  }

  if (questionData.questionCorrectAnswerId2) {
    correctAnswers.push(questionData.questionCorrectAnswerId2);
  }

  if (questionData.questionCorrectAnswerId3) {
    correctAnswers.push(questionData.questionCorrectAnswerId3);
  }

  return (
    <ModalContent my='1.5rem'>
      <ModalHeader fontSize='1.8rem' textAlign='center'>
        {title}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <VStack spacing={3}>
            <FormControl>
              <FormLabel>{t('q-img')}</FormLabel>
              <Input
                // required
                type='file'
                onChange={(e) => {
                  if (e.target.files) {
                    setImage(e.target.files[0]);
                  }
                }}
                variant='flushed'
                mb='10px'
              />
            </FormControl>
            <FlushedTextArea
              isRequired
              name='questiontext'
              value={questionData.questiontext}
              onChange={handleInputChange}
              label={t('q-txt')}
            />
            <FlushedTextArea
              isRequired
              name='questionReasone'
              value={questionData.questionReasone}
              onChange={handleInputChange}
              label={t('reason')}
            />
            <Select
              required
              placeholder={t('lang')}
              name='questionLanguageId'
              value={questionData.questionLanguageId?.toString()}
              onChange={handleInputChange}
            >
              {LANGUAGES.map((lang) => (
                <option key={lang.id} value={lang.id}>
                  {lang.name}
                </option>
              ))}
            </Select>
            <RadioGroup
              aria-required={true}
              display='flex'
              justifyContent='center'
              onChange={(value) => {
                setQuestionData((prev) => {
                  return {
                    ...prev,
                    questionDeptId: +value,
                  };
                });
              }}
            >
              <HStack>
                {PARTS.map((part) => (
                  <Radio
                    key={part.id}
                    value={part.id.toString()}
                    colorScheme='yellow'
                  >
                    {t('part')[part.id - 1]}
                  </Radio>
                ))}
              </HStack>
            </RadioGroup>
          </VStack>
          <QuestionAnswers
            answers={questionData.answers}
            onAddAnswer={handleAddAnswer}
            onAnswerChange={(index, value) => {
              setQuestionData((prev) => {
                const prevAnswers = [...prev.answers];
                prevAnswers[index].answerText = value;
                return {
                  ...prev,
                  answers: prevAnswers,
                };
              });
            }}
            onDeleteAnswer={(index) => {
              setQuestionData((prev) => {
                const newAnswers = [...prev.answers];
                newAnswers.splice(index, 1);
                return {
                  ...prev,
                  answers: newAnswers,
                };
              });
            }}
            setQuestionData={setQuestionData}
            questionCorrectAnswerId={questionData.questionCorrectAnswerId}
            questionCorrectAnswerId2={questionData.questionCorrectAnswerId2}
            questionCorrectAnswerId3={questionData.questionCorrectAnswerId3}
          />

          <Flex mb='20px' justifyContent='center'>
            <Button type='submit' colorScheme='blue' isLoading={isLoading}>
              {btnText}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default MCAModalContent;
