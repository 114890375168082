import {
  Text,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import React from 'react';
import AuthWrapper from '../components/AuthWrapper';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AdminData } from '../types';
import { saveAdminToSession } from '../util/storage';
import api from '../app/api';
import { useTranslate } from '../languages';
import FlushedInput from '../components/FlushedInput';
import FlushedPassword from '../components/FlushedPassword';

interface LoginProps {}

const Login: React.FC<LoginProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [data, setData] = useState<AdminData>({
    adminPasssword: '',
    adminUsername: '',
  });
  const t = useTranslate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await api.post(`/Admin/Admin/Login`, data);
      console.log(res);
      saveAdminToSession(res.data);
      setError('');
      navigate('/');
    } catch (err: any) {
      setError(err.response?.data || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <Text fontWeight={700} fontSize='3rem' mb='4rem'>
        {t('signin')}
      </Text>
      <form onSubmit={handleLogin}>
        {error && (
          <Alert status='error'>
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FlushedInput
          name='adminUsername'
          value={data.adminUsername}
          onChange={handleInputChange}
          type='text'
          variant='flushed'
          placeholder={t('username')}
        />
        <FlushedPassword
          name='adminPasssword'
          value={data.adminPasssword}
          onChange={handleInputChange}
          placeholder={t('pass')}
        />
        <Button
          type='submit'
          fontFamily='Inter'
          borderRadius='0'
          colorScheme='darkBlue'
          size='lg'
          w='100%'
          mb='20px'
        >
          {loading ? <Loader size='md' /> : t('login')}
        </Button>
      </form>
    </AuthWrapper>
  );
};

export default Login;
