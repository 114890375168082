import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  InputLeftElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillEye, AiTwotoneEyeInvisible } from 'react-icons/ai';

interface FlushedPasswordProps extends InputProps {
  label?: string;
}

const FlushedPassword: React.FC<FlushedPasswordProps> = ({
  label,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow((val) => !val);

  return (
    <FormControl mb='30px'>
      {(label || props.placeholder) && (
        <FormLabel>{label ?? props.placeholder}</FormLabel>
      )}
      <InputGroup>
        <Input
          type={show ? 'text' : 'password'}
          p='0'
          ps='1rem'
          borderColor='#7C7B7B'
          variant='flushed'
          fontWeight='500'
          fontSize='1.18rem'
          color='rgba(0, 0, 0, 1)'
          _placeholder={{
            color: 'rgba(0, 0, 0, 1)',
            fontSize: '1rem',
          }}
          {...props}
        />
        <InputRightElement onClick={toggleShow} cursor='pointer'>
          {show ? <AiFillEye /> : <AiTwotoneEyeInvisible />}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default FlushedPassword;
