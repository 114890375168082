import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import Loader from '../../components/Loader';
import { useState } from 'react';
import { Exam, ICreateExam } from '../../types';
import { LANGUAGES } from '../../util/constants';
import { useTranslate } from '../../languages';
import FlushedInput from '../../components/FlushedInput';

interface ExamModalProps {
  handleBtnClick: (exam: Exam | ICreateExam) => void;
  title: string;
  btnText: string;
  isLoading: boolean;
  initialState: Exam | ICreateExam;
}

const ExamModal: React.FC<ExamModalProps> = ({
  btnText,
  handleBtnClick,
  isLoading,
  title,
  initialState,
}) => {
  const [exam, setExam] = useState(initialState);
  const t = useTranslate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleBtnClick(exam);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    let value: string | number = e.target.value;
    if (name === 'examRepeatCount') value = +value;
    setExam((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <ModalContent>
      <ModalHeader fontSize='2rem' textAlign='center'>
        {title}
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <FlushedInput
            isRequired
            name='examName'
            value={exam.examName}
            onChange={handleInputChange}
            type='text'
            placeholder={t('exam-name')}
          />
          <FlushedInput
            isRequired
            name='examNumber'
            value={exam.examNumber}
            onChange={handleInputChange}
            type='text'
            placeholder={t('exam-num')}
          />
          <FlushedInput
            name='examRepeatCount'
            value={exam.examRepeatCount === 0 ? '' : exam.examRepeatCount}
            onChange={handleInputChange}
            type='text'
            placeholder={t('exam-count')}
          />
          <Select
            required
            mb='30px'
            placeholder={t('lang')}
            value={exam.examLanguageId}
            onChange={(e) => {
              setExam((prev) => {
                return {
                  ...prev,
                  examLanguageId: +e.target.value,
                };
              });
            }}
          >
            {LANGUAGES.map((lang) => (
              <option key={lang.id} value={lang.id}>
                {lang.name}
              </option>
            ))}
          </Select>
          <Flex mb='20px' justifyContent='center'>
            <Button type='submit' colorScheme='blue'>
              {isLoading ? <Loader size='md' /> : btnText}
            </Button>
          </Flex>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default ExamModal;
